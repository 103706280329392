@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.mrgStyle_01{
  margin-bottom: 46/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_01{
  margin-top: 8px;
  margin-bottom: 24px;
}
}
////////////////////////////
.mrgStyle_02{
  margin-bottom: 22/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_02{
  margin-bottom: 14px;
}
}
////////////////////////////
.mrgStyle_03{
  margin-bottom: 14/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_03{
  margin-bottom: 5px;
}
}
////////////////////////////
.mrgStyle_04{
  margin-bottom: 24/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_04{
  margin-bottom: 8px;
}
}
////////////////////////////
.mrgStyle_05{
  margin-bottom: 36/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_05{
  margin-bottom: 20px;
}
}
////////////////////////////
.mrgStyle_06{
  margin-bottom: 68/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_06{
  margin-bottom: 28px;
}
}
////////////////////////////
.mrgStyle_07{
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_07{
  margin-bottom: 20px;
}
}
////////////////////////////
.mrgStyle_08{
  margin-bottom: 55/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_08{
  margin-bottom: 28px;
}
}
////////////////////////////
.mrgStyle_09{
  margin-bottom: 46/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_09{
  margin-bottom: 30px;
}
}
////////////////////////////
@media screen and (min-width: $break-point-middle) {
.mrgStyle_10{
  margin-top: 16px;
}
}
////////////////////////////
.mrgStyle_11{
  margin-bottom: 68/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_11{
  margin-bottom: 0px;
}
}
////////////////////////////
.mrgStyle_12{
  margin-bottom: 68/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_12{
  margin-bottom: 0px;
}
}
////////////////////////////
.mrgStyle_13{
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_13{
  margin-bottom: 30px;
}
}
////////////////////////////
.mrgStyle_14{
  padding-top: 90/$design-num-sp*100vw;
  padding-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_14{
  padding-top: 82px;
  padding-bottom: 82px;
}
}
////////////////////////////
.mrgStyle_15{
  margin-bottom: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_15{
  margin-bottom: 20px;
}
}
////////////////////////////
.mrgStyle_16{
  margin-bottom: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_16{
  margin-bottom: 6px;
}
}
////////////////////////////
.mrgStyle_17{
  margin-bottom: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_17{
  margin-bottom: 6px;
}
}
////////////////////////////
.mrgStyle_18{
  margin-bottom: 12/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_18{
  margin-bottom: 8px;
}
}
////////////////////////////
@media screen and (min-width: $break-point-middle) {
.mrgStyle_19{
  margin-top: 38px;
  margin-bottom: 32px;
}
}
////////////////////////////
.mrgStyle_20{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_20{
  margin-bottom: 30px;
}
}
////////////////////////////
.mrgStyle_21{
  margin-bottom: 22/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_21{
  margin-bottom: 14px;
}
}
////////////////////////////
.mrgStyle_22{
  margin-bottom: 26/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_22{
  margin-bottom: 24px;
}
}
////////////////////////////
.mrgStyle_23{
  margin-bottom: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_23{
  margin-bottom: 46px;
}
}
////////////////////////////
.mrgStyle_24{
  padding-top: 90/$design-num-sp*100vw;
  padding-bottom: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_24{
  padding-top: 110px;
  padding-bottom: 110px;
}
}
////////////////////////////
.mrgStyle_25{
  padding-top: 60/$design-num-sp*100vw;
  padding-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_25{
  padding-top: 82px;
  padding-bottom: 82px;
}
}
////////////////////////////
.mrgStyle_26{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_26{
  margin-bottom: 20px;
}
}
////////////////////////////
.mrgStyle_27{
  padding-top: 120/$design-num-sp*100vw;
  padding-bottom: 120/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_27{
  padding-top: 142px;
  padding-bottom: 82px;
}
}
////////////////////////////
.mrgStyle_28{
  padding-top: 120/$design-num-sp*100vw;
  padding-bottom: 120/$design-num-sp*100vw;
  &:nth-child(n+2){
    padding-top: 60/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_28{
  padding-top: 142px;
  padding-bottom: 82px;
  &:nth-child(n+2){
    padding-top: 82px;
  }
}
}
////////////////////////////
.mrgStyle_29{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_29{
  margin-bottom: 60px;
}
}
////////////////////////////
.mrgStyle_30{
  margin-top: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrgStyle_30{
  margin-top: 40px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
