@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.secStyle_01{
  position: relative;
  background-color: $key-gray8;
  padding-left:$sp-pad;
  padding-right:$sp-pad;
  &:nth-child(2n){
    .bg_01 &{
      background-color: $white;
    }
  }
  >.inner{
    opacity: 0;
    position: relative;
    top: 10px;
    //transform: translateY(10px);
    transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
	&.is-active>.inner{
    opacity: 1;
    top: 0;
    //transform: translateY(0px);
	}
}
@media screen and (min-width: $break-point-middle) {
.secStyle_01{
  padding-left:$base-pad;
  padding-right:$base-pad;
}
}
////////////////////////
.secStyle_02{
  position: relative;
  padding-left:$sp-pad;
  padding-right:$sp-pad;
  .bg_02 &{
    border-top:1px solid $key-gray1;
  }
  &.style_01{
    border-top:1px solid $key-gray1;
  }
  >.inner{
    opacity: 0;
    transform: translateY(10px);
    transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
	&.is-active>.inner{
    opacity: 1;
    transform: translateY(0px);
	}
}
@media screen and (min-width: $break-point-middle) {
.secStyle_02{
  padding-left:$base-pad;
  padding-right:$base-pad;
}
}
////////////////////////
.secStyle_03{
  position: relative;
  padding-left:$sp-pad;
  padding-right:$sp-pad;
  background-color: $black;
  color: $white;
  overflow: hidden;
  .bgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .2;
    }
  }
  >.inner{
    position: relative;
    opacity: 0;
    transform: translateY(10px);
    transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
	&.is-active>.inner{
    opacity: 1;
    transform: translateY(0px);
	}
}
@media screen and (min-width: $break-point-middle) {
.secStyle_03{
  padding-left:$base-pad;
  padding-right:$base-pad;
}
}
//////////////////////////////
.secStyle_04{
  position: relative;
  background-color: $black;
  padding-left:$sp-pad;
  padding-right:$sp-pad;
  color: $white;
  // >.inner{
  //   opacity: 0;
  //   transform: translateY(10px);
  //   transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  // }
	// &.is-active>.inner{
  //   opacity: 1;
  //   transform: translateY(0px);
	// }
}
@media screen and (min-width: $break-point-middle) {
.secStyle_04{
  padding-left:$base-pad;
  padding-right:$base-pad;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleWrap_01{
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  >*{
    width: 1/2*100%;
    &:nth-child(n+3){
      margin-top: 20/$design-num-sp*100vw;
    }
  }
  &.ranking{
    counter-reset: number 0;
    >*{
      >a::before{
        counter-increment: number 1;
        content:  counter(number);
      }
    }
  }
  &.style_02{
    >*:nth-child(n+3){
      display: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.articleWrap_01{
  >*{
    width: 1/5*100%;
    &:nth-child(n+3){
      margin-top: 0;
    }
    &:nth-child(n+6){
      margin-top: 40px;
    }
  }
  &.style_01{
    >*:nth-child(n+6){
      display: none;
    }
  }
  &.style_02{
    >*:nth-child(n+3){
      display: block;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.colStyle_01{
  .col-main{
    margin-bottom: 88/$design-num-sp*100vw;
  }
  .col-side{
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_01{
  display: flex;
  justify-content: space-between;
  .col-main{
    width: 840/$content-num-base*100%;
    margin-bottom: 0;
  }
  .col-side{
    width: 261px;
  }
}
}
///////////////////////////////
.colStyle_02{
  >*{
    margin-bottom: 40/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_02{
  display: flex;
  justify-content: space-between;
  >*{
    width: 404/840*100%;
    margin-bottom: 0;
  }
}
}
///////////////////////////////
.colStyle_03{
  .col-main{
    margin-bottom: 86/$design-num-sp*100vw;
  }
  .col-side{
  }
}
@media screen and (min-width: $break-point-middle) {
.colStyle_03{
  display: flex;
  justify-content: space-between;
  .col-main{
    width: 573/1018*100%;
    margin-bottom: 0;
  }
  .col-side{
    width: 340/1018*100%;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.elemWrapStyle_01{
  text-align: center;
  >*{
    display: inline-block;
    min-width: 360/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemWrapStyle_01{
  text-align: left;
  >*{
    min-width: 200px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.setW_01{
  max-width: $content-width-base;
  margin: 0 auto;
}
}
///////////////////////////////////
.setW_02{
  max-width: 668/$design-num-sp*100vw;
  margin: 0 auto;
}
@media screen and (min-width: $break-point-middle) {
.setW_02{
  max-width: 1018px;
  margin: 0 auto;
}
}
///////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.setW_03{
  max-width: 755px;
  margin: 0 auto;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
