@charset "UTF-8";
/* msk-set============================================== */
.msk-set {
    display: block;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: hidden;
}
.msk {
	  display: inline-block;
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
		transition: -webkit-transform 1s cubic-bezier(0.250, 0.82, 0.355, 1);
    transition: transform 1s cubic-bezier(0.250, 0.82, 0.355, 1);
}
.active .msk  {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

/* delay-set============================================== */
.active .delay-01 {
	-webkit-transition-delay: .1s;
    transition-delay: .1s;
}
.active .delay-02 {
	-webkit-transition-delay: .2s;
    transition-delay: .2s;
}
.active .delay-03 {
	-webkit-transition-delay: .3s;
    transition-delay: .3s;
}
.active .delay-04 {
	-webkit-transition-delay: .4s;
    transition-delay: .4s;
}
.active .delay-05 {
	-webkit-transition-delay: .5s;
    transition-delay: .5s;
}
.active .delay-06 {
	-webkit-transition-delay: .6s;
    transition-delay: .6s;
}

/* text-set============================================== */
.js-text__anime {
    overflow: hidden;
    transform: translate(-100%, 0);
    -webkit-transition: all 1s cubic-bezier(0.250, 0.82, 0.355, 1);
    transition: all 1s cubic-bezier(0.250, 0.82, 0.355, 1);
}
.js-text__anime-inner {
    display: inline-block;
    transform: translate(100%, 0);
    -webkit-transition: all 1s cubic-bezier(0.250, 0.82, 0.355, 1);
    transition: all 1s cubic-bezier(0.250, 0.82, 0.355, 1);
}
.active .js-text__anime {
    transform: translate(0, 0);
}
.active .js-text__anime-inner {
    transform: translate(0, 0);
}
.meta-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
}
.c-vrtcl {
  -ms-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  font-feature-settings: 'pkna';
}
.bodr-rds {
  border-radius: 9999px;
}
.pc-block {
  display: block;
}
.sp-block {
  display: none;
}

/* l-main__visual============================================== */
.l-main__visual{
  width: 100%;
  height: calc(100vh + 2.5rem);
  position: relative;
  background-color: #000;
  z-index:0;
}
.l-main__body{
  width: 100%;
  height: calc(100vh + 2.5rem);
  margin: 0 auto 0;
  position:relative;
	background-color: #f4ede5;
  z-index:1;
}
.l-main__body-inner {
  width: 100%;
  height: calc(100vh - 0px);
  position: relative;
  display: flex;
}

/*l-main__meta*/
.l-main__meta {
  width: 100%;
  height: calc(100vh - 0px);
  position:absolute;
  top:0;
  left:0;
  z-index:5;
	pointer-events: none;
}
.l-main__meta-inner {
  width: 100%;
  padding: 0 0 0 8rem;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  z-index:3;
}
.main-meta__copy {
  width: 100%;
  position:relative;
}
.main-meta__copy span {
  display: block;
}
.main-meta__copy span svg {
  fill :#FFF;
}
.copy-first {
  width: 40%;
}
.copy-last {
    width: 39.7%;
    margin: .6rem 0 0 0px;
}
.main-meta__read {
  font-size: 1.5rem;
  color: #FFF;
  padding-left: 0px;
  margin: 2.5rem 0 0;
  letter-spacing: .010em;
	font-style: italic;
}
.main-meta__read span {
  display: inline-block;
}
.main-meta__read span:last-child {
  margin:0;
}
.main-meta__read span.msk-set:nth-child(n - 2) {
		  margin: 0 0 1rem;
}

/*l-main__slide*/
.l-main__slide {
  width: calc(100vw - 0px);
  height: calc(100vh - 6rem);
  position: relative;
  overflow: hidden;
  background-color: #000;
  z-index:0;
}
.l-main__slide:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(../images/common/section-cover.png);
    -webkit-background-size: 2px 2px;
    background-size: 2px 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
		display: none;
}
.l-main__slide-inner {
  position:relative;
  z-index: 1;
}
.l-main__slide .slider-unit {
  width: 100%;
  height: 100%;
}
.l-main__slide .slider-unit .slide-item {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: -25vw;
    z-index: 0;
    -webkit-transform: skewX(15deg);
    transform: skewX(15deg);
    outline: 0px none #000;
    overflow: hidden;
}
.l-main__slide .slider-unit .slide-item .slide-img {
    display: block;
    position: absolute;
    top: 0;
    left: 25vw;
    width: 100vw;
    height: 100%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
}
.l-main__slide .slider-unit .slide-item .slide-img > span {
  width: calc(100% + 100px);
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  outline: 0px none #000;
}
.l-main__slide .slider-unit .slide-item .slide-img > span.slide-pc {
  display: block;
}
.l-main__slide .slider-unit .slide-item .slide-img > span.slide-sp {
  display: none;
}
.l-main__slide .slider-unit .slide-item.is-active {
  -webkit-transition: width .8s cubic-bezier(0.09, 0.34, 0.63, 1);
  transition: width .8s cubic-bezier(0.09, 0.34, 0.63, 1);
  width: 140vw;
}
.l-main__slide .slider-unit .slide-item.is-active .slide-img > span {
  -webkit-transition: -webkit-transform 7s linear;
  transition: -webkit-transform 7s linear;
  transition: transform 7s linear;
  transition: transform 7s linear, -webkit-transform 7s linear;
  -webkit-transform: translateX(0);
   transform: translateX(0);
}
.l-main__slide .slider-navi {
  list-style: none;
  padding: 0;
  position: absolute;
  right: 2rem;
  position: absolute;
  top: 43.4%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
.l-main__slide .slider-navi li {
  width: 3.6rem;
  height: 3.6rem;
  cursor: pointer;
  position: relative;
  line-height: 1;
  margin-bottom: .6rem;
  color: #FFF;
}
.l-main__slide .slider-navi li:after {
  position:absolute;
  content:'';
  font-size: 1.2rem;
  left:-9rem;
  top:1.3rem;
  font-weight: bold;
  text-align: right;
  opacity:.6;
}
.l-main__slide .slider-navi li:nth-child(1):after {
  content:'FRAGRANCE';
}
.l-main__slide .slider-navi li:nth-child(2):after {
  content:'OUTDOOR';
  left:-7.8rem;
}
.l-main__slide .slider-navi li:nth-child(3):after {
  content:'SPORTS';
  left:-6.4rem;
}
.l-main__slide .slider-navi li:last-child {
  margin:0;
}
.l-main__slide .slider-navi li:before {
  width: .7rem;
  height: .7rem;
  position: relative;
  line-height: 1;
  border-radius: 50%;
  background-color:#FFF;
  position:absolute;
  content:'';
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity:.6;
}
.l-main__slide .slider-navi li.is-current:before,
.l-main__slide .slider-navi li.is-current:after {
  opacity:1;
}
.l-main__slide .slider-navi li.is-current:before {
	background-color:#81c0e9;
}
.l-main__slide .slider-navi li svg {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.l-main__slide .slider-navi li svg .circle {
   fill: none;
  stroke: #81c0e9;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-dashoffset: 246;
  stroke-dasharray: 82 82;
}
.slide-common {
  display: none;
}

/*l-main__scrool*/
.l-main__scroll {
    width: 2rem;
    height: 18rem;
    position: absolute;
    bottom: 4.5rem;
    left: 2.3rem;
    z-index: 10;
}
.scroll-label {
    font-size: 1.3rem;
		font-weight: bold;
    color: #FFF;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -o-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    position: absolute;
    left: 50%;
    margin-left: .3rem;
    top: 21%;
}
.scroll-line.scroll-line {
    width: .3rem;
    height: auto;
    margin: 3rem auto 0;
    position: absolute;
    left: calc(50% - -.7rem);
    top: 40%;
    z-index: 1;
}
.scroll-line-ob-container {
    position: relative;
    overflow: hidden;
    width: .3rem;
    height: 4rem;
}
.scroll-line-ob {
    position: absolute;
    left: 0;
    display: inline-block;
    height: 75%;
    width: .3rem;
    background-color: #FFF;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.scroll-line .ob01 {
    -webkit-animation: scroll-line 2s linear 0s infinite;
    animation: scroll-line 2s linear 0s infinite;
}
.scroll-line .ob02 {
    -webkit-animation: scroll-line-b 2s linear 1s infinite;
    animation: scroll-line-b 2s linear 1s infinite;
}
@-webkit-keyframes scroll-line {
0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
}
}
@keyframes scroll-line{
	0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
}
}
@-webkit-keyframes scroll-line-b {
0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
}
}
@keyframes scroll-line-b{
	0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}
100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
}
}


/* p-section__index ============================================== */
.p-section__index {
  position: relative;
	z-index: 2;
}
.section-index__flex {
  width: 100%;
	position: relative;
  display: flex;
	pointer-events: auto;
}
.p-section__index-inner {
  position: relative;
}
.p-section__title {
  font-size: 5rem;
	font-weight: bold;
	display: inline-block;
}
.p-top-about .p-section__title {
  font-size: 3rem;
	font-style: italic;
	padding-left: .8rem;
	padding-right: 1rem;
	display: inline-block;
}
.c-b {
  color: #000;
}
.c-w {
  color: #FFF;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-center {
  text-align: center;
}



/* footer============================================== */
.l-footer {
  width: 100%;
	margin: 0 auto;
	position: relative;
	background-color: #f2f3f4;
	padding: 0 0 0 8rem;
}


@media screen and (max-width: 1024px) {

/* l-main__visual============================================== */
.l-main__meta-inner {
    padding: 0 0 0 6rem;
}
.l-main__scroll {
    left: 1.3rem;
}

/* footer============================================== */
.l-footer {
    padding: 0 0 0 6rem;
}



}

@media screen and (max-width: 959px) {

/* l-main__visual============================================== */
.l-main__visual {
    height: calc(65rem + 2.5rem);
}
.l-main__body {
    height: calc(65rem + 2.5rem);
}
.l-main__body-inner {
    height: 100%;
}
.l-main__slide {
    height: calc(65rem - 2.5rem);
}
.l-main__meta {
    height: 100%;
}
.l-main__meta-inner {
    padding: 0 0 0 5rem;
}
.copy-first {
    width: 50%;
}
.copy-last {
    width: 49.7%;
}
.main-meta__read span.msk-set:nth-child(n - 2) {
    margin: 0 0 0.5rem;
}
.l-main__scroll {
    bottom: 1.4rem;
    left: 0.8rem;
}
.scroll-label {
    font-size: 1.1rem;
    top: 28%;
}


}

@media screen and (max-width: 769px) {

.pc-block {
  display: none;
}
.sp-block {
  display: block;
}


/* l-main__visual============================================== */
.l-main__visual {
		height: auto;
}
.l-main__body {
    height: auto;
}
.l-main__body-inner {
    height: auto;
    display: block;
}
.l-main__slide {
    height: calc(55rem - 0rem);
}
.l-main__slide .slider-unit .slide-item.is-active {
    width: 150vw;
}
.l-main__slide .slider-unit .slide-item .slide-img > span.slide-sp {
    display: block;
}
.l-main__slide .slider-unit .slide-item .slide-img > span.slide-pc {
    display: none;
}
.l-main__slide .slider-navi {
    top: 33.5rem;
    left: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
		-webkit-transform: translateY(0%);
    transform: translateY(0%);
}
.l-main__slide .slider-navi li {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 7.5rem 0 0;
}
.l-main__slide .slider-navi li {
    display: inline-block;
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
    transition: -webkit-transform 1s cubic-bezier(0.250, 0.82, 0.355, 1);
    transition: transform 1s cubic-bezier(0.250, 0.82, 0.355, 1);
		-webkit-transition-delay: .7s;
    transition-delay: .7s;
}
.active .slider-navi li {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}
.l-main__slide .slider-navi li:after,
.l-main__slide .slider-navi li:nth-child(2):after,
.l-main__slide .slider-navi li:nth-child(3):after {
    top: .35rem;
    left: 2.2rem;
    font-size: 1rem;
}
.l-main__meta-inner {
    padding: 0 0 0 2rem;
		top: 13rem;
    transform: translateY(0%);
}
.main-meta__copy {
    width: 96%;
}
.copy-first {
    width: 89%;
}
.copy-last {
    width: 88.7%;
		margin: 0.3rem 0 0 0px;
}
.main-meta__read {
    font-size: 1.4rem;
    margin: 1.5rem 0 0;
		letter-spacing: .05em;
}
.main-meta__read span {
  display: block;
}
.main-meta__read span.msk-set:nth-child(n - 2) {
    margin: 0 0 0.7rem;
}
.main-meta__read br {
  display: none;
}

.l-main__scroll {
    width: 1rem;
    height: 12rem;
		bottom: 7.8rem;
    left: -.2rem;
		display: none;
}
.scroll-label {
    font-size: 1rem;
}
.scroll-line.scroll-line {
    width: .2rem;
}


}
