@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.articleStyle_01{
  position: relative;
  opacity: 0;
  transform: translateY(10px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.is-active{
    opacity: 1;
    transform: translateY(0px);
	}
  >a{
    display: block;
    background-color: $white;
    padding-bottom: 100/$design-num-sp*100vw;
    position: relative;
    height: 100%;
    h2{
      @include fz_vw(22);
      line-height: 1.6em;
      font-weight: 600;
      margin: 0;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  >a:hover{
    opacity: 1;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01{
  >a{
    padding-bottom: 50px;
    h2{
      font-size: 1.3rem;
    }
  }
  >a:hover{
    opacity: .9;
  }
}
}
//////////////////////////
.articleStyle_01 >a .eyecatch{
  position: relative;
  overflow: hidden;
  .imgStyle{
    display: block;
    position: relative;
    overflow: hidden;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $black;
    }
  }
  img{
    display: block;
    @include imgset;
    height: auto;
    aspect-ratio:1/1;
    object-fit: cover;
    transition: .6s;
    transform-origin: 50% 50%;
  }
}
.articleStyle_01.is-active >a .eyecatch .imgStyle::before{
  animation: .8s slideOutRight $ease-out-quad forwards;
}
.articleStyle_01:nth-child(2n).is-active >a .eyecatch .imgStyle::before{
  animation: .8s slideOutDown $ease-out-quad forwards;
}
.articleStyle_01:nth-child(3n).is-active >a .eyecatch .imgStyle::before{
  animation: .8s slideOutLeft $ease-out-quad forwards;
}
.articleStyle_01:nth-child(4n).is-active >a .eyecatch .imgStyle::before{
  animation: .8s slideOutUp $ease-out-quad forwards;
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01 >a:hover .eyecatch{
  img{
    transform: scale(1.03);
  }
}
}
//////////////////////////
.articleStyle_01 >a .itemLabel{
  display: flex;
  position: absolute;
  li{
    span{
      display: block;
      color: $white;
      text-align: center;
      line-height: 1em;
      @include fz_vw(20);
      padding: 8/$design-num-sp*100vw;
    }
  }
  &.style_01{
    left: 0;
    bottom: 0;
    display: none;
  }
  &.style_02{
    display: none;
    right: 0;
    bottom: 0;
    // top: 0;
  }
}
.articleStyle_01.new >a .itemLabel{
  &.style_01{
    display: block;
  }
}
.articleStyle_01.sale >a .itemLabel{
  &.style_02{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01 >a .itemLabel{
  li{
    span{
      font-size: 1.1rem;
      padding: 4px 8px;
    }
  }
}
}
//////////////////////////
.articleStyle_01 >a{
  //counterはoutlineに設定
  &::before{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 46/$design-num-sp*100vw;
    height: 46/$design-num-sp*100vw;
    line-height: 46/$design-num-sp*100vw;
    text-align: center;
    @include fz_vw(22);
    color: $white;
    background-color: $rank-color4;
    z-index: 10;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01 >a{
  &::before{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1.3rem;
  }
}
}
.articleStyle_01:nth-child(1) >a::before{
  background-color: $rank-color1;
}
.articleStyle_01:nth-child(2) >a::before{
  background-color: $rank-color2;
}
.articleStyle_01:nth-child(3) >a::before{
  background-color: $rank-color3;
}
//////////////////////////
.articleStyle_01 >a .content{
  padding: 20/$design-num-sp*100vw 20/$design-num-sp*100vw 0 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01 >a .content{
  padding: 10px 16px 0px 16px;
}
}
//////////////////////////
.articleStyle_01 .directCheck{
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $key-gray2;
    padding: 20/$design-num-sp*100vw;
    height: 60/$design-num-sp*100vw;
    background-color: $black;
    color: $white;
    >span{
      @include fz_vw(26);
      line-height: 1em;
    }
    .labelIcon{
      padding-right: 8/$design-num-sp*100vw;
      position: relative;
      top: -1px;
    }
    &.sold{
      display: none;
      background-color: $key-gray4;
      border: 1px solid $key-gray4;
      color: $white;
      cursor: default;
      pointer-events: none;
    }
  }
}
.articleStyle_01.sold .directCheck{
  a.normal{
    display: none;
  }
  a.sold{
    display: flex;
  }
}
@media screen and (min-width: $break-point-middle) {
.articleStyle_01 .directCheck{
  a{
    padding: 6px 10px;
    height: 30px;
    >span{
      font-size: 1.3rem;
    }
    .labelIcon{
      padding-right: 4px;
      top: 0;
    }
    &.normal .labelTx{
      display: none;
    }
  }
  a:hover{
    opacity: 1;
    background-color: $accent-color2;
    border-right: 1px solid $accent-color2;
  }
}
}
@media screen and (min-width: 980px) {
.articleStyle_01 .directCheck{
  a{
    &.normal .labelTx{
      display: inline-block;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
