@charset "utf-8";
* {
 @include box-sizing(border-box);
}

*:before, *:after {
 @include box-sizing(border-box);
}

html {
	//height: 100%;
  font-size: 62.5%;
  //height: -webkit-fill-available;
}

body {
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
	// color: $text-color;
	// font-family: $base-font-family;
	// line-height: $base-line-height;
	// background-color: $bg-color;
	// -webkit-font-smoothing: antialiased;
	// -webkit-text-rendering: optimizelegibility;
	// font-size: 1.4rem;
	// font-weight: 400;
  // text-size-adjust: none;
  // -webkit-text-size-adjust: none;
  // position: relative;
  font-family: $base-font-family;
}

// input, button, select, textarea {
// 	font-family: inherit;
// 	font-size: inherit;
// 	line-height: inherit;
// }
//
// ::selection, ::-moz-selection {
//  color: #fff;
//  background-color: #eee;
// }

p{
  font-weight: 500;
}

.pc {
	display: none!important;
}

.sp {
	display: block!important;
}

.pcInline{
	display: none!important;
}

.spInline {
	display:inline!important;
}

.pcInlineB{
	display: none!important;
}

.spInlineB{
	display:inline-block!important;
}

.pcTd{
	display: none!important;
}

.spTd {
	display:table-cell!important;
}

.pcTbl{
	display: none!important;
}

.spTbl{
	display:table!important;
}

a:hover,a:active{
	//text-decoration:none!important;
	//color:inherit!important;
	img{
		@include opacity(1);
	}
}

//br.sp,br.spInline,br.spTd{
//	&:after {
//		margin-right: -.333em;
//		content:"";
//	}
//}

@media screen and (min-width: $break-point-middle) {
.widedev {
	display: none!important;
}

.pc {
	display: block!important;
}

.sp {
	display: none!important;
}

.pcInline {
	display:inline!important;
}

.spInline {
	display: none!important;
}

.pcInlineB {
	display:inline-block!important;
}

.spInlineB {
	display: none!important;
}

.pcTd {
	display:table-cell!important;
}

.spTd {
	display: none!important;
}

.pcTbl {
	display:table!important;
}

.spTbl {
	display: none!important;
}
}

@media screen and (min-width: $design-width) {
.widedev {
	display: block!important;
}
}

@media screen and (min-width: $break-point-full) {
}

@media screen and (min-width: $break-point-large) {
}

@media screen and (min-width: 1190px) {
}

@media screen and (max-width: 320px) {
}
