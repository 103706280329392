@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrapper{
  color: $text-color;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis{
  margin-top: $header-total-sp;
  position: relative;
  background-color: $black;
}
@media screen and (min-width: $break-point-middle) {
#mainVis{
  margin-top: $header-total;
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .l-main__slide{
  // height: 924/$design-num-sp*100vw;
  opacity: 0;
  transition: .8s;
  $head: 420/$design-num-sp*100vw;
  $head-news: 380/$design-num-sp*100vw;
  height: calc(100vh - #{$head-news});
  .hasNews &{
    height: calc(100vh - #{$head});
  }
  .loaded &{
    opacity: 1;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .l-main__slide{
  //height: 800/$design-num*100vw;
  //max-height: 800px;
  height: calc(100vh - 70px);
  .hasNews &{
    height: calc(100vh - 105px);
  }
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .l-main__scroll{
  display: none;
}
@media screen and (min-width: $break-point-middle) {
#mainVis .l-main__scroll{
  display: block;
  bottom: 0;
  height: 11rem;
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .scroll-label{
  font-size: 1.4rem;
  line-height: 1em;
  letter-spacing: .03em;
  top: 0%;
}
#mainVis .scroll-line.scroll-line{
  top: 35%;
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .visContent{
  position: absolute;
  width: auto;
  top: 0;
  left: 0;
  z-index: 10;
  color: $white;
  >.inner{
    padding: 0 $sp-pad;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visContent{
  $visGap:170/$design-num*100vw;
  top: calc(50% - #{$visGap});
  // width: 70%;
  // height: 100%;
  // display: flex;
  // align-items: center;
  >.inner{
    padding: 0;
    padding-left: $base-pad;
  }
}
}
////////////////////////////
#mainVis .visContent h2{
  margin: 100/$design-num-sp*100vw 0 30/$design-num-sp*100vw 0;
  span{
    @include fz_vw(80);
    line-height: (100/80)+em;
    font-weight: 900;
    margin: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visContent h2{
  margin: 0 0 20px 0;
  span{
    font-size: 5.6rem;
    line-height: (65/56)+em;
  }
}
}
@media screen and (min-width: $design-width) {
#mainVis .visContent h2{
  span{
    @include fz_vwpc(56);
  }
}
}
////////////////////////////
#mainVis .visSummary{
  @include fz_vw(28);
  line-height: (40/28)+em;
  p{
    font-weight: 400;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visSummary{
  font-size: 1.5rem;
  line-height: (27/15)+em;
  p{
    font-weight: 400;
  }
}
}
@media screen and (min-width: $design-width) {
#mainVis .visSummary{
  @include fz_vwpc(15);
}
}
////////////////////////////
#mainVis .visBtn{
  margin-top: 30/$design-num-sp*100vw;
  >*{
    width: 226/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visBtn{
  margin-top: 30px;
  >*{
    width: 150px;
  }
}
}
@media screen and (min-width: $design-width) {
#mainVis .visBtn{
  >*{
    width: 150/$design-num*100vw;
  }
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.l-main__slide .slider-navi {
  top: 590/$design-num-sp*100vw;
  left: $sp-pad;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
@media screen and (min-width: $break-point-middle) {
.l-main__slide .slider-navi {
  top: 43.4%;
  left: auto;
  right: 2rem;
  display: block;
  position: absolute;
  width: auto;
  overflow: visible;
}
}
.l-main__slide .slider-navi li{
  position: relative;
  &.is-current::after{
    opacity: 1!important;
  }
}
.l-main__slide .slider-navi li:after,
.l-main__slide .slider-navi li:nth-child(2):after,
.l-main__slide .slider-navi li:nth-child(3):after {
  top: .35rem;
  left: 2.2rem;
  font-size: 1rem;
  white-space: nowrap;
}
@media screen and (min-width: $break-point-middle) {
.l-main__slide .slider-navi li:after,
.l-main__slide .slider-navi li:nth-child(2):after,
.l-main__slide .slider-navi li:nth-child(3):after  {
  font-size: 1.2rem;
  left:-9rem;
  top:1.3rem;
  font-weight: bold;
  text-align: right;
  opacity:.6;
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.visBottomCont{
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: $break-point-middle) {
.visBottomCont{
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.fixedBnrWrap .visBnr{
  background-color:rgba(0, 0, 0, .8);
  position: fixed;
  bottom: -400/$design-num-sp*100vw;
  left: 0;
  width: 100%;
  opacity: 1;
  z-index: 100;
  padding: 20/$design-num-sp*100vw;
  transition: .7s;
  //transition-delay: .8s;
  a{
    font-size: 0;
  }
  .up &{
    bottom: 0;
  }
  img{
    @include imgset;
    height: auto;
  }
  a{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.fixedBnrWrap .visBnr{
  background-color: transparent;
  left: auto;
  right: 26px;
  bottom: 26px;
  padding: 0;
  width: 256px;
  margin-left: auto;
  box-sizing: content-box;
  opacity: 0;
  .home &,.home.up &{
    bottom: 91px;
  }
  img{
    @include imgset;
  }
  a{
    display: block;
  }
  .loaded &{
    opacity: 1;
  }
  .up &{
    bottom: 26px;
  }
  .hideBnr &{
    opacity: 0;
  }
  .bnrShadow &{
    @include basicShadow;
    bottom: 26px;
  }
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .visBnr{
  background-color: $key-gray8;
  position: relative;
  padding: 38/$design-num-sp*100vw $sp-pad;
  opacity: 0;
  transition: .8s;
  transition-delay: .8s;
  .loaded &{
    opacity: 1;
  }
  img{
    @include imgset;
    height: auto;
  }
  a{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visBnr{
  display: none;
  // background-color: transparent;
  // position: relative;
  // padding: 0;
  // padding-right: 26px;
  // margin-bottom: 26px;
  // width: 256px;
  // margin-left: auto;
  // box-sizing: content-box;
  // img{
  //   @include imgset;
  // }
  // a{
  //   display: block;
  // }
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#mainVis .visKeyword{
  height: 94/$design-num-sp*100vw;
  >.inner{
    @include setTbl;
    height: 100%;
  }
  .col-ttl{
    background-color: $black;
    color: $white;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    width: 200/$design-num-sp*100vw;
  }
  .bgElem{
    position: absolute;
    left: 20/$design-num-sp*100vw;
    top: -20/$design-num-sp*100vw;
    img{
      width: 181/$design-num-sp*100vw;
      opacity: .2;
    }
  }
  h2{
    line-height: 1em;
    @include fz_vw(24);
    margin: 0;
  }
  .col-content{
    background-color: $white;
    padding-left: 10/$design-num-sp*100vw;
    >.inner{
      overflow: hidden;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#mainVis .visKeyword{
  padding-left: $base-pad;
  height: 60px;
  .col-ttl{
    border-radius: 10px 0 0 0;
    width: 140px;
  }
  .bgElem{
    left: 12px;
    top: -7px;
    img{
      width: 116px;
    }
  }
  h2{
    font-size: 1.5rem;
  }
  .col-content{
    padding-left: 10px;
  }
}
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tagLoop{
  @include killSpace;
  white-space: nowrap;
  font-size: 0;
  ul{
    @include killSpace;
    white-space: nowrap;
    padding-inline-start: 0px;
  }
  li{
    line-height: 1em;
    &:nth-child(n+21){
      display: none;
    }
  }
  &.move{
    ul:first-child{
      animation:loop 40s -20s linear infinite;
    }
    ul:last-child{
      animation:loop2 40s linear infinite;
    }
    &:hover{
      ul:first-child,ul:last-child{
        animation-play-state:paused;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
// .tagLoop{
//   li{
//     &:nth-child(n+21){
//       display: none;
//     }
//   }
// }
}
////////////
@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}
///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.snsIcons{
  display: flex;
  align-items: center;
  li{
    margin-right: 16/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    width: 72/$design-num-sp*100vw;
    height: 72/$design-num-sp*100vw;
    display: block;
    @include fz_vw(36);
    line-height: 72/$design-num-sp*100vw;
    color: $white;
    background-color: $text-color;
    text-align: center;
    border-radius: 100%;
    i{
      line-height: 72/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.snsIcons{
  li{
    margin-right: 8px;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    width: 40px;
    height: 40px;
    font-size: 2.0rem;
    i{
      line-height: 40px;
    }
  }
  a:hover{
    opacity: 1;
  }
  li.facebook a:hover{
    background-color: $facebook-color;
  }
  li.twitter a:hover{
    background-color: $twitter-color;
  }
  li.line a:hover{
    background-color: $line-color;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.largeBnr{
  position: relative;
  background-color: $black;
  color: $white;
  >.inner{
    overflow: hidden;
    height: 547/$design-num-sp*100vw;
  }
  .bgImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .6;
    transition: .6s;
    overflow: hidden;
    img{
      @include imgset;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transform-origin: 50% 50%;
      transition: .9s;
    }
  }
  .effectImg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../images/bg_02.webp');
  }
  .content{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
  }
  a{
    color: $white;
  }
  .place{
    @include fz_vw(20);
    line-height: 1em;
    font-family: $fontIt;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 24/$design-num-sp*100vw;
    display: block;
    text-align: center;
    //@include over_01($white);
  }
  .mainLink{
    display: block;
    h2{
      @include fz_vw(48);
      line-height: 1em;
      margin-bottom: 24/$design-num-sp*100vw;
      font-weight: 600;
      position: relative;
      &::before{
        content: '';
        height: 1px;
        width: 0%;
        left: 0;
        bottom: 0;
        position: absolute;
        @include transition(.3s);
        background-color: $white;
      }
    }
    dl{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    dt{
      @include fz_vw(18);
      line-height: 1em;
      padding: 6/$design-num-sp*100vw 20/$design-num-sp*100vw;
      border: 1px solid $white;
      margin-right: 16/$design-num-sp*100vw;
    }
    dd{
      @include fz_vw(30);
      line-height: 1em;
      letter-spacing: .04em;
    }
    .circle{
      display: block;
      width: 81/$design-num-sp*100vw;
      height: 81/$design-num-sp*100vw;
      border-radius: 100%;
      border: 1px solid $white;
      transition: .3s;
      text-align: center;
      margin: 36/$design-num-sp*100vw auto 0;
      i{
        font-weight: 500;
        line-height: 81/$design-num-sp*100vw;
        @include fz_vw(16);
        transition: .3s;
        position: relative;
        left: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.largeBnr{
  >.inner{
    max-height: 580px;
    height: 580/$design-num*100vw;
  }
  &.ov .bgImg{
    opacity: .7;
    img{
      transform: scale(1.02);
    }
  }
  .effectImg{
    display: none;
  }
  .place{
    font-size: 2.0rem;
    margin-bottom: 28px;
    // &:hover{
    //   opacity: .9;
    // }
  }
  .mainLink{
    h2{
      font-size: 4.8rem;
      margin-bottom: 18px;
    }
    dt{
      font-size: 1.8rem;
      padding: 8px 40px;
      margin-right: 16px;
    }
    dd{
      font-size: 3.0rem;
    }
    .circle{
      width: 67px;
      height: 67px;
      margin: 36px auto 0;
      i{
        line-height: 67px;
        font-size: 1.4rem;
      }
    }
  }
  .mainLink:hover{
    opacity: .9;
    h2{
      &::before{
        width: 100%;
      }
    }
    .circle{
      background-color: $accent-color1;
      border: 1px solid $accent-color1;
      i{
        left: 5px;
        color: $black;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.faqWrap{
  >*{
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.faqWrap{
  >*{
    margin-bottom: 40px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.faqItem{
  background-color: $white;
  border-radius: 5px;
  padding: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.faqItem{
  padding: 20px;
}
}
////////////////////////////////////////////////////////////
.faqTtl{
  @include fz_vw(38);
  line-height: 1.6em;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $key-gray1;
  padding-bottom: 20/$design-num-sp*100vw;
  &::before{
    content: 'Q.';
    @include fz_vw(38);
    position: relative;
    top: -1px;
    padding-right: 20/$design-num-sp*100vw;
    color: $accent-color2;
  }
}
@media screen and (min-width: $break-point-middle) {
.faqTtl{
  font-size: 2.4rem;
  line-height: 1.6em;
  padding-bottom: 10px;
  &::before{
    font-size: 2.6rem;
    padding-right: 10px;
  }
}
}
////////////////////////////////////////////////////////////
.faqCont{
  padding: 36/$design-num-sp*100vw 0px 0px 60/$design-num-sp*100vw;
  >.inner{
    position: relative;
    &::before{
      content: 'A.';
      position: absolute;
      top: 0;
      left: -50/$design-num-sp*100vw;
      @include fz_vw(40);
      line-height: 1em;
      color: $accent-color1;
    }
  }
  .summary{
    >*{
      margin-bottom: 1.6em;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.faqCont{
  padding: 18px 0px 0px 40px;
  >.inner{
    &::before{
      left: -30px;
      font-size: 2.2rem;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
