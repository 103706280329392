@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carouselStyle{
  .screen{
    position: relative;
    width: 100%;
    height: 100%;
  }
  // .screen>.carouselItem:nth-child(n+2){
  //   display: none;
  // }
  .screen>.carouselItem,.thumbnail>.carouselItem{
    display: none;
  }
  .slick-slide{
    font-size: 0;
  }
  button{
    outline:0;
    @include appearance(none);
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carouselStyle_01{
  .item{
    img{
      display: block;
      @include imgset;
      height: auto;
    }
  }
  .screen{
  }
  .slick-list{
    border-radius: 6px;
    border: 1px solid $key-gray5;
  }
  .slick-dots{
    width: 80%;
    margin: 0 auto;
    position: relative;
    bottom: auto;
  }
  .slick-dots li{
    margin: 0 10/$design-num-sp*100vw;
  }
  .slick-dots li, .slick-dots li button{
    width: 14/$design-num-sp*100vw;
    height: 14/$design-num-sp*100vw;
  }
  .slick-dots li button{
    padding: 0;
  }
  .slick-dots li button:before{
    width: 14/$design-num-sp*100vw;
    height: 14/$design-num-sp*100vw;
    line-height: 14/$design-num-sp*100vw;
  }
  .slick-next, .slick-prev{
    top: auto;
    bottom: -25/$design-num-sp*100vw;
  }
  .slick-next{
    right: 60/$design-num-sp*100vw;
  }
  .slick-prev{
    left: 60/$design-num-sp*100vw;
  }
  .slick-next:after, .slick-prev:after{
    display: none;
  }
  .slick-next:before, .slick-prev:before {
    @include iconFont;
    @include fz_vw(20);
    color: $black;
    opacity: 1;
  }
  .slick-next:before{
    content: '\EA13';
  }
  .slick-prev:before{
    content: '\EA14';
  }
}
@media screen and (min-width: $break-point-middle) {
.carouselStyle_01{
  .slick-dots li{
    margin: 0 4px;
  }
  .slick-dots li, .slick-dots li button{
    width: 6px;
    height: 6px;
  }
  .slick-dots li button:before{
    width: 6px;
    height: 6px;
    line-height: 6px;
  }
  .slick-next, .slick-prev{
    bottom: -12px;
  }
  .slick-next{
    right: 40px;
  }
  .slick-prev{
    left: 40px;
  }
  .slick-next:before, .slick-prev:before {
    font-size: 1.2rem;
  }
}
}
//////////////////////////////////
.carouselStyle_02{
  .item{
    img{
      display: block;
      @include imgset;
      height: auto;
    }
  }
  .screen{
    margin-bottom: 40/$design-num-sp*100vw;
  }
  .slick-dots{
    display: none;
  }
  .slick-next, .slick-prev{
    display: none;
  }
  .slick-next:after, .slick-prev:after{
    display: none;
  }
  .slick-next:before, .slick-prev:before {
    display: none;
  }
  .thumbnail{
    margin-left: -8/$design-num-sp*100vw;
    margin-right: -8/$design-num-sp*100vw;
    .slick-slide{
      opacity: .7;
      cursor: pointer;
      transition: .3s;
    }
    .slick-current{
      opacity: 1;
      cursor: default;
      pointer-events: none;
    }
  }
  .thumbnail .item{
    padding: 0 8/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.carouselStyle_02{
  .screen{
    margin-bottom: 20px;
  }
  .slick-next, .slick-prev{
    display: inline-block;
    // top: auto;
    // bottom: -12px;
    width: 47px;
    height: 47px;
    background-color: $black;
    text-align: center;
    border-radius: 100%;
    transition: .3s;
    &:hover{
      background-color: $key-gray2;
    }
  }
  .slick-next{
    right: -23px;
  }
  .slick-prev{
    left: -23px;
  }
  .slick-next:after, .slick-prev:after{
    display: none;
  }
  .slick-next:before, .slick-prev:before {
    display: inline-block;
    @include iconFont;
    font-size: 1.6rem;
    color: $white;
    opacity: 1;
    line-height: 47px;
  }
  .slick-next:before{
    content: '\EA13';
  }
  .slick-prev:before{
    content: '\EA14';
  }
  .thumbnail{
    margin-left: -7px;
    margin-right: -7px;
    .slick-slide{
      &:hover{
        opacity: .9;
      }
    }
  }
  .thumbnail .item{
    padding: 0 7px;
  }
}
}
//////////////////////////////////
.carouselStyle_03{
  @extend .carouselStyle_01;
  .slick-list{
    border-radius: 0px;
    border: none;
  }
  .slick-next:before, .slick-prev:before {
    color: $white;
  }
  .slick-dots li.slick-active button:before{
    color: $white;
  }
  .slick-dots li button:before{
    color: $white;
  }
}
//////////////////////////////////
.carouselStyle_04{
  @extend .carouselStyle_02;
  .item{
    img{
      width: 100%;
      aspect-ratio:1/1;
      object-fit: cover;
    }
  }
}
////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////
// .carouselStyle_02{
//   .item{
//     img{
//       aspect-ratio:1/1;
//       object-fit: cover;
//     }
//   }
//   .slick-dots li, .slick-dots li button{
//     width: 20/$design-num-sp*100vw;
//     height: 20/$design-num-sp*100vw;
//     margin: 0 6/$design-num-sp*100vw;
//   }
//   .slick-dots li button:before{
//     width: 20/$design-num-sp*100vw;
//     height: 20/$design-num-sp*100vw;
//     opacity: 1;
//     content: '';
//     line-height: 20/$design-num-sp*100vw;
//     border: 1px solid $text-color;
//     background-color: transparent;
//     border-radius: 100%;
//     transition: .3s;
//   }
//   .slick-dots li.slick-active button:before{
//     background-color: $text-color;
//     cursor: default;
//     pointer-events: none;
//   }
// }
// @media screen and (min-width: $break-point-middle) {
// .carouselStyle_02{
//   .item{
//     img{
//       aspect-ratio:980/550;
//     }
//   }
//   .slick-dots li, .slick-dots li button{
//     width: 10px;
//     height: 10px;
//     margin: 0 3px;
//   }
//   .slick-dots li button:before{
//     width: 10px;
//     height: 10px;
//     line-height: 10px;
//   }
//   .slick-dots li button:hover:before{
//     background-color: $text-color;
//     opacity: .5;
//   }
// }
// }
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
