@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttlSet_01{
  display: flex;
  align-items: center;
  >*{
    &:first-child{
      margin-right: 10/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlSet_01{
  >*{
    &:first-child{
      margin-right: 12px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttlStyle_01{
  font-weight: 600;
  @include fz_vw(28);
  line-height: 1em;
  letter-spacing: .03em;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_01{
  font-size: 1.4rem;
}
}
///////////////////////////////////////
.ttlStyle_02{
  @include fz_vw(36);
  line-height: 1.5em;
  padding-left: 22/$design-num-sp*100vw;
  letter-spacing: .06em;
  border-left: 4px solid $accent-color1;
  font-weight: 600;
  margin-bottom: 24/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_02{
  font-size: 1.6rem;
  padding-left: 12px;
  margin-bottom: 6px;
}
}
///////////////////////////////////////
.ttlStyle_03{
  @extend .ttlStyle_02;
  border-left: 4px solid $accent-color2;
}
///////////////////////////////////////
.ttlStyle_04{
  @extend .ttlStyle_02;
  border-left: 4px solid $accent-color3;
}
///////////////////////////////////////
.ttlStyle_05{
  @include fz_vw(32);
  line-height: 1em;
  font-weight: 600;
  letter-spacing: .03em;
  margin-bottom: 24/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_05{
  font-size: 1.5rem;
  margin-bottom: 8px;
}
}
///////////////////////////////////////
.ttlStyle_06{
  @include fz_vw(62);
  line-height: 1em;
  margin: 0;
  font-weight: 700;
  span.result{
    @include fz_vw(30);
    >span{
      &::after{
        content: ',';
      }
      &:last-child::after{
        display: none;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_06{
  font-size: 4.0rem;
  span.result{
    font-size: 1.4rem;
  }
}
}
///////////////////////////////////////
.ttlStyle_07{
  color: $key-gray9;
  @include fz_vw(24);
  line-height: 1.6em;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_07{
  font-size: 1.4rem;
}
}
///////////////////////////////////////
.ttlStyle_08{
  @include fz_vw(30);
  line-height: 1em;
  font-weight: 600;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_08{
  font-size: 1.8rem;
}
}
///////////////////////////////////////
.ttlStyle_09{
  @extend .ttlStyle_06;
  text-align: center;
}
///////////////////////////////////////
.ttlStyle_10{
  @extend .ttlStyle_07;
  text-align: center;
  color: $key-gray11;
}
///////////////////////////////////////
.ttlStyle_11{
  @extend .ttlStyle_06;
  @include fz_vw(36);
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_11{
  font-size: 1.6rem;
}
}
///////////////////////////////////////
.ttlStyle_12{
  @include fz_vw(36);
  line-height: 1em;
  font-weight: 600;
  border-left: 4px solid $key-gray4;
  padding-left: 20/$design-num-sp*100vw;
  margin-bottom: 30/$design-num-sp*100vw;
  border-bottom: 1px solid $key-gray4;
  padding-bottom: 10/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttlStyle_12{
  font-size: 2.2rem;
  padding-left: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.btnStyle_01{
  @include btnsetting;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 71/$design-num-sp*100vw;
  color: $white;
  border-radius: 100px;
  border: 1px solid $white;
  position: relative;
  overflow: hidden;
  line-height: 1em;
  padding: 20/$design-num-sp*100vw;
  @include fz_vw(24);
  &::before{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    background-color: $accent-color2;
    transition: .3s;
  }
  >span{
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_01{
  min-height: 50px;
  border-radius: 100px;
  padding: 16px;
  font-size: 1.4rem;
  &:hover{
    opacity: 1;
    border: 1px solid $accent-color2;
    &::before{
      left: 0;
    }
  }
}
}
@media screen and (min-width: $design-width) {
.btnStyle_01{
  padding: 16/$design-num*100vw;
  @include fz_vwpc(14);
}
}
///////////////////////////////////////
.btnStyle_02{
  display: flex;
  align-items: center;
  justify-content: center;
  @include btnsetting;
  border-radius: 2px;
  background-color: $black;
  min-height: 108/$design-num-sp*100vw;
  padding: 30/$design-num-sp*100vw;
  font-weight: 500;
  @include fz_vw(32);
  line-height: 1em;
  letter-spacing: .03em;
  position: relative;
  overflow: hidden;
  color: $white;
  >span{
    position: relative;
  }
  &::before{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    transition: .3s;
    background-color: $accent-color1;
  }
}
@media screen and (min-width: $break-point-middle) {
.btnStyle_02{
  min-height: 60px;
  padding: 16px;
  font-size: 1.8rem;
  &:hover{
    opacity: .9;
    &::before{
      left: 0;
    }
  }
  &:active{
    &::before{
      left: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.summaryStyle_01{
  @include fz_vw(24);
  line-height: (34/24)+em;
}
@media screen and (min-width: $break-point-middle) {
.summaryStyle_01{
  font-size: 1.1rem;
  line-height: (20/11)+em;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.linkStyle_01{
  line-height: 1em;
  @include fz_vw(22);
  @include over_02($text-color);
  overflow: hidden;
  display: inline-block;
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_01{
  line-height: 1em;
  font-size: 1.2rem;
}
}
//////////////////////////
.linkStyle_02{
  @include over_01($text-color);
}
//////////////////////////
.linkStyle_03{
  @extend .linkStyle_02;
  @include fz_vw(24);
  line-height: 1em;
  font-weight: 600;
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_03{
  font-size: 1.1rem;
}
}
//////////////////////////
.linkStyle_04{
  .labelTx{
    @include fz_vw(24);
    line-height: 1em;
    overflow: hidden;
    display: inline-block;
    position: relative;
    &::before{
      content: '';
      height: 1px;
      width: 100%;
      background-color: $text-color;
      bottom: 0;
      position: absolute;
      @include transform(translateX(0%));
    }
  }
  .labelIcon{
    @include fz_vw(22);
    line-height: 1em;
    position: relative;
    padding-left: 12/$design-num-sp*100vw;
    top: 4px;
    transition: .2s;
  }
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_04{
  .labelTx{
    font-size: 1.2rem;
  }
  .labelIcon{
    font-size: 1.2rem;
    padding-left: 4px;
  }
  &:hover{
    .labelTx{
      &::before{
        animation:linemove_01 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
      }
    }
    .labelIcon{
      top: 2px;
    }
  }
}
}
//////////////////////////
.linkStyle_05{
  text-decoration: underline;
  color: $link-color;
}
@media screen and (min-width: $break-point-middle) {
.linkStyle_05{
  &:hover{
    text-decoration: none;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.listStyle_01{
  @include killSpace;
  li{
    margin-right: 42/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.listStyle_01{
  li{
    margin-right: 14px;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.dlStyle_01{
  display: flex;
  @include fz_vw(24);
  line-height: (34/24)+em;
  dt{
    &::after{
      content: ':';
      padding: 0 10/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.dlStyle_01{
  font-size: 1.2rem;
  line-height: (20/12)+em;
  dt{
    &::after{
      padding: 0 4px;
    }
  }
}
}
////////////////////////////////////////
.dlStyle_02{
  dt{
    @include fz_vw(32);
    line-height: 1em;
    font-weight: 600;
    margin-bottom: 24/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.dlStyle_02{
  display: flex;
  align-items: center;
  dt{
    font-size: 1.3rem;
    padding-right: 16px;
    margin-bottom: 0;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.navStyle_01{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid $key-gray5;
  border-bottom: none;
  border-right: none;
  li{
    width: 1/3*100%;
    border: 1px solid $key-gray5;
    border-left: none;
    border-top: none;
  }
  a{
    font-weight: 600;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 85/$design-num-sp*100vw;
    overflow: hidden;
    position: relative;
    @include fz_vw(26);
    line-height: 1em;
    .labelTx{
      position: relative;
      transition: .3s;
    }
    .labelTxEn{
      display: none;
    }
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      bottom: 0;
      width: 100%;
      transition: .3s;
      background-color: $accent-color1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_01{
  a{
    min-height: 51px;
    font-size: 1.5rem;
  }
  a:hover{
    opacity: 1;
    .labelTx{
      color: $white;
    }
    &::before{
      left: 0;
    }
  }
}
}
//////////////////////////////////////////
.navStyle_02{
  @extend .navStyle_01;
  a{
    @include fz_vw(22);
    .labelIcon{
      @include fz_vw(28);
      line-height: 1em;
      padding-right: 8/$design-num-sp*100vw;
      position: relative;
      transition: .3s;
      .myfont-fav{
        position: relative;
        top: -1px;
      }
      .myfont-user_01,.myfont-user_02{
        position: relative;
        top: -2px;
      }
    }
    &:before{
      background-color: $accent-color2;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_02{
  a{
    font-size: 1.4rem;
    .labelIcon{
      font-size: 1.6rem;
      padding-right: 4px;
    }
  }
  a:hover{
    .labelIcon{
      color: $white;
    }
  }
}
}
//////////////////////////////////////////
.navStyle_03{
  @extend .navStyle_02;
  a{
    &:before{
      background-color: $accent-color3;
    }
  }
  li{
    width: 1/2*100%;
  }
}
//////////////////////////////////////////
.navStyle_04{
  ul{
    @include killSpace;
  }
  li{
    min-width: 270/$design-num-sp*100vw;
    padding-right: 20/$design-num-sp*100vw;
    &:nth-child(2n){
      padding-right: 0;
    }
    &:nth-child(n+3){
      margin-top: 50/$design-num-sp*100vw;
    }
  }
  a{
    @include fz_vw(36);
    font-weight: 600;
    line-height: 1em;
    @include over_01($white);
    letter-spacing: .03em;
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_04{
  li{
    min-width: 0;
    margin-right: 24px;
    padding-right: 0;
    &:last-child{
      margin-right: 0;
    }
    &:nth-child(n+3){
      margin-top: 0;
    }
  }
  a{
    font-size: 1.7rem;
  }
}
}
///////////////////////////////////////////
.navStyle_05{
  ul{
    display: inline;
    @include killSpace;
  }
  li{
    min-width: 240/$design-num-sp*100vw;
    padding-right: 20/$design-num-sp*100vw;
    @include fz_vw(24);
    line-height: 1em;
    margin-bottom: 32/$design-num-sp*100vw;
    &:nth-child(2n){
      padding-right: 0;
    }
  }
  a{
    @include over_01($white);
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_05{
  ul{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  li{
    display: block;
    min-width: 0;
    padding-right: 0;
    margin-right: 30px;
    font-size: 1.3rem;
    margin-bottom: 0;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
///////////////////////////////////////////
.navStyle_06{
  ul{
    display: flex;
    align-items: center;
  }
  li{
    margin-right: 30/$design-num-sp*100vw;
    @include fz_vw(22);
    line-height: 1em;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    color: $key-gray6;
    @include over_01($key-gray6);
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_06{
  li{
    margin-right: 30px;
    font-size: 1.2rem;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
///////////////////////////////////////////
.navStyle_07{
  ul{
    display: inline;
    @include killSpace;
  }
  li{
    margin-right: 30/$design-num-sp*100vw;
    @include fz_vw(20);
    line-height: 1em;
  }
  a{
    color: $key-gray6;
    @include over_01($key-gray6);
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_07{
  ul{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  li{
    display: block;
    margin-right: 24px;
    font-size: 1.0rem;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
///////////////////////////////////////////
.navStyle_08{
  ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  li{
    width: 1/2*100%;
    padding: 0 10/$design-num-sp*100vw;
    text-align: center;
    height: 375/$design-num-sp*100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before,&::after{
      content: '';
      background-color: $key-gray7;
      position: absolute;
    }
    &::before{
      width: 1px;
      height: 280/$design-num-sp*100vw;
      right: 0;
      top: (375 - 280)/2/$design-num-sp*100vw;
    }
    &::after{
      width: 338/$design-num-sp*100vw;
      height: 1px;
      left: 0;
      top: 0;
      display: none;
    }
    &:nth-child(2n){
      &::before{
        display: none;
      }
      &::after{
        left: auto;
        right: 0;
      }
    }
    &:nth-child(n+3){
      &::after{
        display: block;
      }
    }
  }
  a{
    display: block;
    color: $white;
    overflow: hidden;
    position: relative;
    >span{
      display: block;
      text-align: center;
    }
    .labelIcon{
      width: 181/$design-num-sp*100vw;
      height: 181/$design-num-sp*100vw;
      text-align: center;
      background-color: $white;
      border-radius: 100%;
      color: $black;
      @include fz_vw(76);
      margin: 0 auto 26/$design-num-sp*100vw;
      i{
        line-height: 181/$design-num-sp*100vw;
      }
    }
    .labelTx{
      font-weight: 600;
      @include fz_vw(28);
      line-height: 1em;
      margin-bottom: 10/$design-num-sp*100vw;
    }
    .labelTxEn{
      @include fz_vw(22);
      line-height: 1em;
      color: $key-gray10;
    }
    &::after{
      content: '';
      width: 0%;
      height: 1000px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $accent-color1;
      transform: skewX(15deg);
      transition: width .8s cubic-bezier(0.06, 0.34, 0.63, 1);
      mix-blend-mode: multiply;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.navStyle_08{
  li{
    border-right: 1px solid $key-gray7;
    width: 1/4*100%;
    padding: 0 10px;
    height: auto;
    display: block;
    &:last-child{
      border: none;
    }
    &::before,&::after{
      display: none;
    }
    &:nth-child(n+3){
      &::after{
        display: none;
      }
    }
  }
  a{
    display: inline-block;
    .labelIcon{
      width: 80px;
      height: 80px;
      font-size: 3.6rem;
      margin: 0 auto 18px;
      i{
        line-height: 80px;
      }
    }
    .labelTx{
      font-size: 1.5rem;
      margin-bottom: 7px;
    }
    .labelTxEn{
      font-size: 1.0rem;
    }
  }
  a:hover{
    opacity: 1;
    &::after{
      width: 400%;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tagStyle_01{
  li{
    cursor: pointer;
    background-color: $key-gray8;
    border: 1px solid $key-gray5;
    border-radius: 100px;
    margin: 0 8/$design-num-sp*100vw;
    position: relative;
    overflow: hidden;
    transition: .3s;
    &::before{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      bottom: 0;
      transition: .3s;
      background-color: $accent-color1;
    }
    >span{
      position: relative;
      display: flex;
      min-height: 40/$design-num-sp*100vw;
      align-items: center;
      justify-content: center;
      padding: 10/$design-num-sp*100vw 16/$design-num-sp*100vw;
    }
    span.labelTx{
      line-height: 1em;
      @include fz_vw(22);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tagStyle_01{
  li{
    margin: 0 4px;
    >span{
      min-height: 30px;
      padding: 10px 13px;
    }
    span.labelTx{
      font-size: 1.3rem;
    }
    a:hover{
      opacity: 1;
    }
    &:hover{
      color: $white;
      border: 1px solid $accent-color1;
      a{
        color: $white;
      }
      &::before{
        left: 0;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.labelStyle_01{
  background-color: $key-red;
}
////////////////
.labelStyle_02{
  background-color: $key-gray2;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.txStyle_01{
  @include fz_vw(22);
  line-height: 1em;
  font-weight: 600;
  >span{
    @include fz_vw(30);
    line-height: 1em;
    padding-left: 6/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.txStyle_01{
  font-size: 1.3rem;
  >span{
    font-size: 1.6rem;
    padding-left: 3px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.categoryStyle_01,a .categoryStyle_01{
  display: flex;
  align-items: center;
  li{
    span{
      color: $key-gray10;
      @include fz_vw(20);
      line-height: 1em;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.categoryStyle_01,a .categoryStyle_01{
  li{
    span{
      font-size: 1.0rem;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
