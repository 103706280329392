@font-face {
    font-family: "myfont";
    src: url('../fonts/myfont.eot');
    src: url('../fonts/myfont.eot?#iefix') format('eot'),
        url('../fonts/myfont.woff') format('woff'),
        url('../fonts/myfont.ttf') format('truetype'),
        url('../fonts/myfont.svg#myfont') format('svg');
    font-weight: normal;
    font-style: normal;
}
.myfont {
    display: inline-block;
    font-family: "myfont";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.myfont-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.myfont-2x { font-size: 2em; }
.myfont-3x { font-size: 3em; }
.myfont-4x { font-size: 4em; }
.myfont-5x { font-size: 5em; }
.myfont-fw {
    width: 1.2857142857142858em;
    text-align: center;
}
.myfont-search:before { content: "\EA01" }
.myfont-mail_01:before { content: "\EA02" }
.myfont-user_01:before { content: "\EA03" }
.myfont-sign_in:before { content: "\EA04" }
.myfont-sign_out:before { content: "\EA05" }
.myfont-fav:before { content: "\EA06" }
.myfont-user_02:before { content: "\EA07" }
.myfont-cart:before { content: "\EA08" }
.myfont-mail_02:before { content: "\EA09" }
.myfont-payment:before { content: "\EA0A" }
.myfont-shipping:before { content: "\EA0B" }
.myfont-return:before { content: "\EA0C" }
.myfont-faq:before { content: "\EA0D" }
.myfont-line:before { content: "\EA0E" }
.myfont-facebook:before { content: "\EA0F" }
.myfont-twitter:before { content: "\EA10" }
.myfont-arrow_01:before { content: "\EA11" }
.myfont-arrow_02:before { content: "\EA12" }
.myfont-arrow_03:before { content: "\EA13" }
.myfont-arrow_04:before { content: "\EA14" }
.myfont-arrow_05:before { content: "\EA15" }
