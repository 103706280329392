@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.inputStyle_01{
  width: 100%;
  position: relative;
  .btnSubmit{
    color: $key-gray4;
    line-height: 1em;
    font-size: 1.6rem;
    position: absolute;
    right: 10px;
    top: calc(50% - .8rem);
    display: block;
    cursor: pointer;
  }
  input{
    width: 100%;
    background-color: $key-gray3;
    border: 1px solid $key-gray3;
    @include appearance(none);
  	margin: 0;
  	border: none;
    border-radius: 2px;
  	outline: none;
    font-size: 1.3rem;
    line-height: 1em;
    min-height: 38px;
    transition: .3s;
    padding-left: 8px;
    padding-right: 36px;
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"]{
      -moz-appearance:textfield;
    }
    &:focus{
    	background-color:$white;
  		border:1px solid $black;
  	}
  	/* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
  		color:$key-gray4;
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
  		color:$key-gray4;
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
  		color:$key-gray4;
  	}
  }
}
/////////////////////////////////////////////
.inputStyle_02{
  @extend .inputStyle_01;
  .btnSubmit{
    @include fz_vw(40);
    right: 20/$design-num-sp*100vw;
    $pos:20/$design-num-sp*100vw;
    top: calc(50% - #{$pos});
  }
  input{
    border: 1px solid $key-gray5;
    min-height: 110/$design-num-sp*100vw;
    padding-left: 20/$design-num-sp*100vw;
    padding-right: 85/$design-num-sp*100vw;
    @include fz_vw(32);
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
      @include fz_vw(32);
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
      @include fz_vw(32);
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
      @include fz_vw(32);
  	}
  }
}
@media screen and (min-width: $break-point-middle) {
.inputStyle_02{
  .btnSubmit{
    font-size: 1.6rem;
    right: 10px;
    top: calc(50% - .8rem);
  }
  input{
    min-height: 50px;
    padding-left: 12px;
    padding-right: 36px;
    font-size: 1.7rem;
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
      font-size: 1.7rem;
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
      font-size: 1.7rem;
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
      font-size: 1.7rem;
  	}
  }
}
}
/////////////////////////////////////////////
.inputStyle_03{
  @extend .inputStyle_01;
  input{
    border: 1px solid $key-gray5;
    min-height: 110/$design-num-sp*100vw;
    padding-left: 20/$design-num-sp*100vw;
    padding-right: 85/$design-num-sp*100vw;
    @include fz_vw(32);
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
      @include fz_vw(32);
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
      @include fz_vw(32);
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
      @include fz_vw(32);
  	}
  }
}
@media screen and (min-width: $break-point-middle) {
.inputStyle_03{
  input{
    min-height: 60px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 1.7rem;
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
      font-size: 1.7rem;
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
      font-size: 1.7rem;
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
      font-size: 1.7rem;
  	}
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.selectStyle_01{
	position:relative;
  transition: .3s;
	&:before{
    @include iconFont;
		content:"\EA12";
		position:absolute;
		line-height:0;
    top: calc(50% - .8rem);
		right:20/$design-num-sp*100vw;
		color:$black;
		z-index:10;
    @include fz_vw(30);
    line-height: 1em;
	}
	select{
    transition: .3s;
		width:100%;
		position:relative;
		z-index:2;
		border:none;
		@include appearance(none);
    @include fz_vw(32);
    border-radius: 2px;
    background-color: $key-gray3;
		padding:0 20/$design-num-sp*100vw;
    min-height: 110/$design-num-sp*100vw;
		outline:0;
    border: 1px solid $key-gray5;
		@include box-shadow(0);
    color: $key-black;
		&:focus{
  		border:1px solid $black;
			@include box-shadow(0);
			outline:0;
      background-color: transparent;
		}
	}
	select::-ms-expand {
		display: none;
	}
  option{
		width:100%;
	}
}
@media screen and (min-width: $break-point-middle) {
.selectStyle_01{
  background-color: $key-gray3;
	&:before{
		right:20px;
		z-index:1;
		font-size:1.6rem;
	}
	select{
    font-size: 1.7rem;
		background-color:transparent;
		padding:0 12px;
    min-height: 60px;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.formColStyle_01{
  display: flex;
  align-items: center;
  .col{
    width: 240/$design-num-sp*100vw;
  }
  .sep{
    @include fz_vw(32);
    padding: 0 10/$design-num-sp*100vw;
    font-weight: 600;
  }
  .label{
    @include fz_vw(30);
    font-weight: 600;
    padding-left: 10/$design-num-sp*100vw;
    margin-top: auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.formColStyle_01{
  .col{
    width: 154/404*100%;
  }
  .sep{
    font-size: 1.7rem;
    padding: 0 14px;
  }
  .label{
    font-size: 1.8rem;
    padding-left: 8px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
