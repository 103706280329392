@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer{
  width: 100%;
  padding: 130/$design-num-sp*100vw $sp-pad;
  background-color: $black;
  color: $white;
  a{
    color: $white;
  }
  >.inner{
    width: 100%;
    max-width: 590/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
footer{
  height: $footer-size;
  padding: $base-pad;
  display: flex;
  align-items: center;
  >.inner{
    max-width: $content-width-base;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.footColumn{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .col{
    &.style_01{
      width: 270/$design-num*100vw;
      max-width: 270px;
    }
    &.style_02{
      width: 396px;
      // width: 396/$design-num*100vw;
      // max-width: 396px;
    }
    &.style_03{
      width: 285/$design-num*100vw;
      max-width: 285px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footLogo{
  margin-bottom: 58/$design-num-sp*100vw;
  svg{
    fill:$white;
    width: 138/$design-num-sp*100vw;
    height: 132/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.footLogo{
  margin-bottom: 26px;
  svg{
    width: 92px;
    height: 89px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footInfo{
  margin-top: 48/$design-num-sp*100vw;
  padding-top: 48/$design-num-sp*100vw;
  border-top: 1px solid $key-gray7;
  .linkStyle_01,.linkStyle_02{
    color: $white;
    &::before{
      background-color: $white;
    }
  }
  .linkStyle_03{
    color: $key-gray6;
    &::before{
      background-color: $key-gray6;
    }
  }
  .linkStyle_04{
    .labelTx{
      &::before{
        background-color: $white;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.footInfo{
  margin-top: 0;
  padding-top: 0;
  border: none;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.footNavWrap{
  margin-top: 48/$design-num-sp*100vw;
  padding-top: 48/$design-num-sp*100vw;
  border-top: 1px solid $key-gray7;
}
@media screen and (min-width: $break-point-middle) {
.footNavWrap{
  margin-top: 22px;
  padding-top: 22px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.copyRightSec{
  padding-top: 120/$design-num-sp*100vw;
  small{
    display: block;
    text-align: center;
    color: $key-gray6;
    @include fz_vw(22);
    line-height: 1em;
    font-weight: 500;
  }
}
@media screen and (min-width: $break-point-middle) {
.copyRightSec{
  padding-top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 38px;
  small{
    display: block;
    text-align: center;
    color: $key-gray6;
    font-size: 1.0rem;
    line-height: 1em;
    font-weight: 500;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
