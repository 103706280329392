@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#headLine{
  background-color: $black;
  //position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  article{
    height: $headLine-sp;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  a{
    color: $white;
    line-height: 1em;
    @include over_01($white);
    h2{
      @include fz_vw(20);
      font-weight: normal;
      margin-bottom: 0;
    }
  }
  .menuOpen &{
    display: none;
  }
}
@media screen and (min-width: $break-point-middle) {
#headLine{
  article{
    height: $headLine;
  }
  a{
    h2{
      font-size: 1.1rem;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header {
  width: 100%;
  padding: 0;
  top:0;
  z-index: 1000;
  border-bottom: 1px solid $key-gray1;
  .home &{
    border: none;
  }
  .hasNews &{
    top:$headLine-sp;
  }
  // .scrollHeader.hasNews &,.menuOpen.hasNews &{
  //   top: 0;
  // }
  >.inner{
    display: flex;
    height: $header-size-sp;
    align-items: center;
    justify-content: space-between;
    .menuOpen &{
      justify-content: flex-end;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
header {
  //makeshop初期値
  // background: #fff;
  // margin: 0 auto;
  // text-align: center;
  // position: fixed;
  // z-index: 2;
  // transition: .3s;
  .hasNews &{
    top:$headLine;
  }
  // .scrollHeader.hasNews &,.menuOpen.hasNews &{
  //   top: 0;
  // }
  >.inner{
    height: $header-size;
  }
}
}
/////////////////////////
header .col-logo{
  padding: 0 20/$design-num-sp*100vw;
  box-sizing: content-box;
  height: 100%;
  display: flex;
  align-items: center;
  img{
    @include imgset;
    width: 316/$design-num-sp*100vw;
  }
  .menuOpen &{
    display: none;
  }
}
@media screen and (min-width: $break-point-middle) {
header .col-logo{
  padding: 0 24px;
  border-right: 1px solid $key-gray1;
  img{
    width: 209px;
    // width: 209/$design-num*100vw;
    // min-width: 130px;
    // max-width: 209px;
  }
}
}
/////////////////////////
header .col-input{
  display: none;
}
@media screen and (min-width: 960px) {
header .col-input{
  display: block;
  width: 100%;
  padding: 0 14px;
  border-right: 1px solid $key-gray1;
  height: 100%;
  display: flex;
  align-items: center;
  .menuOpen &{
    display: none;
  }
}
}
/////////////////////////
header .col-nav{
  width: 100%;
  padding: 20/$design-num-sp*100vw 0px;
  .menuOpen &{
    display: none;
  }
  .inner{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media screen and (min-width: $break-point-middle) {
header .col-nav{
  width: auto;
  max-width: 590px;
  min-width: 500px;
  padding: 16px 20px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.gNav{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  li{
    a{
      width: 102/$design-num-sp*100vw;
      text-align: center;
      display: block;
      position: relative;
      >span{
        display: block;
      }
    }
    &.spHide{
      display: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.gNav{
  justify-content: space-between;
  li{
    a{
      width: auto;
      padding: 0 5px;
    }
    &.spHide{
      display: block;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header{
  .labelTx{
    @include fz_vw(20);
    line-height: 1em;
    white-space: nowrap;
  }
  .labelIcon{
    @include fz_vw(36);
    line-height: 1em;
    margin-bottom: 10/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
header{
  .labelTx{
    font-size: 1.0rem;
  }
  .labelIcon{
    font-size: 2.4rem;
    margin-bottom: 6px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.col-menu{
  border-left: 1px solid $key-gray1;
  height: 100%;
  display: flex;
  align-items: center;
  .menuOpen &{
    border: none;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuBtn{
  cursor: pointer;
  width: $header-size;
  .iconCel{
    height: 2.4rem;
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: $break-point-middle) {
#menuBtn{
  cursor: pointer;
  width: $header-size;
  .iconCel{
    height: 2.4rem;
    display: flex;
    align-items: center;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuBtn .burger{
  width: 43/$design-num-sp*100vw;
  height: 21/$design-num-sp*100vw;
  position: relative;
  margin: 0 auto;
  top: 2px;
  >span{
    display: block;
    width: 100%;
    height: 2px;
    background-color: $key-black;
    position: absolute;
    left: 0;
    transition: .3s;
    &:nth-child(1){
      top: 0;
    }
    &:nth-child(2){
      bottom: 0;
    }
  }
}
#menuBtn.active .burger >span{
  &:nth-child(1){
    transform: translateY(18/$design-num-sp*100vw) rotate(45deg);
  }
  &:nth-child(2){
    transform: translateY(0/$design-num-sp*100vw) rotate(-45deg);
  }
}
@media screen and (min-width: $break-point-middle) {
#menuBtn .burger{
  width: 23px;
  height: 13px;
}
#menuBtn.active .burger >span{
  &:nth-child(1){
    transform: translateY(9px) rotate(45deg);
  }
  &:nth-child(2){
    transform: translateY(-2px) rotate(-45deg);
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.contents{
  //height: 1000px;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
