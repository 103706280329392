@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#outerMenu{
	@include opacity(1);
	position: relative;
	//padding-bottom: $footer-size-sp;
	.menuOpen &,.modalOpen &{
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#clickBlocker{
	width: 100%;
	height: 0%;
	position:fixed;
	top: 0;
	left: 0;
	z-index: -1;
	background-color: $white;
	//background-color: rgba(0, 0, 0, .3);
	transition: .3s $ease-out-quad;
	//background-color:rgba(255, 255, 255, .8);
	//display: none;
	.menuOpen &{
		//display: block;
		width: 100%;
		height: 100%;
		z-index: 600;
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#menuPanel{
  height: 0;
	opacity: 0;
	position: fixed;
	z-index: 0;
	transition: .6s;
	width: 100%;
	overflow: hidden;
	transition: height .6s $ease-out-quad,opacity .6s $ease-out-quad;
	.menuOpen &{
    height: auto;
		opacity: 1;
		z-index: 900;
		width: 100%;
		position: relative;
	}
	>.wrapper{
		position: relative;
		box-sizing: content-box;
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 40/$design-num-sp*100vw;
		.menuOpen &{
			min-height: 100vh;
		}
	}
	>.wrapper>.inner{
		width: 100%;
		position: relative;
		max-width: $content-width-base;
		margin: 0 auto;
		padding: 180/$design-num-sp*100vw 0;
	}
}
@media screen and (min-width: $break-point-middle) {
#menuPanel{
	>.wrapper{
		padding: 0 $base-pad;
	}
	>.wrapper>.inner{
		padding: 115px 0;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#siteMenu{
	position: relative;
	width: 100%;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.siteMenuLogo{
	width: 501/$design-num-sp*100vw;
	margin: 0 auto 40/$design-num-sp*100vw;
	img{
		@include imgset;
	}
}
@media screen and (min-width: $break-point-middle) {
.siteMenuLogo{
	width: auto;
	margin: 0;
	img{
		@include imgset;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.headSec{
	margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.headSec{
	margin-bottom: 34px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.siteMenuSec{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	.col{
		&.style_01{
			width: 292/$design-num*100vw;
			max-width: 292px;
		}
		&.style_02{
			width: 400/$design-num*100vw;
			max-width: 400px;
		}
		&.style_03{
			width: 250/$design-num*100vw;
			max-width: 250px;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
