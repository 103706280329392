@charset "UTF-8";
@keyframes bounceY {
  0% {
    transform: translateY(1em);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes line_animation {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes line_animation2 {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes line_animation3 {
  0% {
    stroke-dashoffset: 2000;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

@keyframes line_animation4 {
  0% {
    stroke-dashoffset: 100;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

@keyframes sizeScale {
  0% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -o-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
}

@keyframes handwriting_01 {
  0% {
    opacity: 0;
    transform: translateY(1em);
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    fill: transparent;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    stroke-dashoffset: 0;
  }
}

@keyframes handwriting_02 {
  0% {
    opacity: 0;
    transform: translateY(1em);
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    opacity: 1;
    transform: translateY(0);
    fill: transparent;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    stroke-dashoffset: 0;
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "DM Sans", "Noto Sans JP", sans-serif;
}

p {
  font-weight: 500;
}

.pc {
  display: none !important;
}

.sp {
  display: block !important;
}

.pcInline {
  display: none !important;
}

.spInline {
  display: inline !important;
}

.pcInlineB {
  display: none !important;
}

.spInlineB {
  display: inline-block !important;
}

.pcTd {
  display: none !important;
}

.spTd {
  display: table-cell !important;
}

.pcTbl {
  display: none !important;
}

.spTbl {
  display: table !important;
}

a:hover img, a:active img {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .widedev {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
  .pcInline {
    display: inline !important;
  }
  .spInline {
    display: none !important;
  }
  .pcInlineB {
    display: inline-block !important;
  }
  .spInlineB {
    display: none !important;
  }
  .pcTd {
    display: table-cell !important;
  }
  .spTd {
    display: none !important;
  }
  .pcTbl {
    display: table !important;
  }
  .spTbl {
    display: none !important;
  }
}

@media screen and (min-width: 1320px) {
  .widedev {
    display: block !important;
  }
}

@font-face {
  font-family: "myfont";
  src: url("../fonts/myfont.eot");
  src: url("../fonts/myfont.eot?#iefix") format("eot"), url("../fonts/myfont.woff") format("woff"), url("../fonts/myfont.ttf") format("truetype"), url("../fonts/myfont.svg#myfont") format("svg");
  font-weight: normal;
  font-style: normal;
}

.myfont {
  display: inline-block;
  font-family: "myfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myfont-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.myfont-2x {
  font-size: 2em;
}

.myfont-3x {
  font-size: 3em;
}

.myfont-4x {
  font-size: 4em;
}

.myfont-5x {
  font-size: 5em;
}

.myfont-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.myfont-search:before {
  content: "\EA01";
}

.myfont-mail_01:before {
  content: "\EA02";
}

.myfont-user_01:before {
  content: "\EA03";
}

.myfont-sign_in:before {
  content: "\EA04";
}

.myfont-sign_out:before {
  content: "\EA05";
}

.myfont-fav:before {
  content: "\EA06";
}

.myfont-user_02:before {
  content: "\EA07";
}

.myfont-cart:before {
  content: "\EA08";
}

.myfont-mail_02:before {
  content: "\EA09";
}

.myfont-payment:before {
  content: "\EA0A";
}

.myfont-shipping:before {
  content: "\EA0B";
}

.myfont-return:before {
  content: "\EA0C";
}

.myfont-faq:before {
  content: "\EA0D";
}

.myfont-line:before {
  content: "\EA0E";
}

.myfont-facebook:before {
  content: "\EA0F";
}

.myfont-twitter:before {
  content: "\EA10";
}

.myfont-arrow_01:before {
  content: "\EA11";
}

.myfont-arrow_02:before {
  content: "\EA12";
}

.myfont-arrow_03:before {
  content: "\EA13";
}

.myfont-arrow_04:before {
  content: "\EA14";
}

.myfont-arrow_05:before {
  content: "\EA15";
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../lib/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../lib/slick/slick.eot");
  src: url("../lib/slick/slick.eot?#iefix") format("embedded-opentype"), url("../lib/slick/slick.woff") format("woff"), url("../lib/slick/slick.ttf") format("truetype"), url("../lib/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* msk-set============================================== */
.msk-set {
  display: block;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: hidden;
}

.msk {
  display: inline-block;
  -webkit-transform: translateY(110%);
  transform: translateY(110%);
  transition: -webkit-transform 1s cubic-bezier(0.25, 0.82, 0.355, 1);
  transition: transform 1s cubic-bezier(0.25, 0.82, 0.355, 1);
}

.active .msk {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/* delay-set============================================== */
.active .delay-01 {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.active .delay-02 {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
}

.active .delay-03 {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}

.active .delay-04 {
  -webkit-transition-delay: .4s;
  transition-delay: .4s;
}

.active .delay-05 {
  -webkit-transition-delay: .5s;
  transition-delay: .5s;
}

.active .delay-06 {
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

/* text-set============================================== */
.js-text__anime {
  overflow: hidden;
  transform: translate(-100%, 0);
  -webkit-transition: all 1s cubic-bezier(0.25, 0.82, 0.355, 1);
  transition: all 1s cubic-bezier(0.25, 0.82, 0.355, 1);
}

.js-text__anime-inner {
  display: inline-block;
  transform: translate(100%, 0);
  -webkit-transition: all 1s cubic-bezier(0.25, 0.82, 0.355, 1);
  transition: all 1s cubic-bezier(0.25, 0.82, 0.355, 1);
}

.active .js-text__anime {
  transform: translate(0, 0);
}

.active .js-text__anime-inner {
  transform: translate(0, 0);
}

.meta-text {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
}

.c-vrtcl {
  -ms-writing-mode: tb-rl;
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  font-feature-settings: 'pkna';
}

.bodr-rds {
  border-radius: 9999px;
}

.pc-block {
  display: block;
}

.sp-block {
  display: none;
}

/* l-main__visual============================================== */
.l-main__visual {
  width: 100%;
  height: calc(100vh + 2.5rem);
  position: relative;
  background-color: #000;
  z-index: 0;
}

.l-main__body {
  width: 100%;
  height: calc(100vh + 2.5rem);
  margin: 0 auto 0;
  position: relative;
  background-color: #f4ede5;
  z-index: 1;
}

.l-main__body-inner {
  width: 100%;
  height: calc(100vh - 0px);
  position: relative;
  display: flex;
}

/*l-main__meta*/
.l-main__meta {
  width: 100%;
  height: calc(100vh - 0px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
}

.l-main__meta-inner {
  width: 100%;
  padding: 0 0 0 8rem;
  position: absolute;
  top: 46%;
  transform: translateY(-50%);
  z-index: 3;
}

.main-meta__copy {
  width: 100%;
  position: relative;
}

.main-meta__copy span {
  display: block;
}

.main-meta__copy span svg {
  fill: #FFF;
}

.copy-first {
  width: 40%;
}

.copy-last {
  width: 39.7%;
  margin: .6rem 0 0 0px;
}

.main-meta__read {
  font-size: 1.5rem;
  color: #FFF;
  padding-left: 0px;
  margin: 2.5rem 0 0;
  letter-spacing: .010em;
  font-style: italic;
}

.main-meta__read span {
  display: inline-block;
}

.main-meta__read span:last-child {
  margin: 0;
}

.main-meta__read span.msk-set:nth-child(n - 2) {
  margin: 0 0 1rem;
}

/*l-main__slide*/
.l-main__slide {
  width: calc(100vw - 0px);
  height: calc(100vh - 6rem);
  position: relative;
  overflow: hidden;
  background-color: #000;
  z-index: 0;
}

.l-main__slide:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(../images/common/section-cover.png);
  -webkit-background-size: 2px 2px;
  background-size: 2px 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
}

.l-main__slide-inner {
  position: relative;
  z-index: 1;
}

.l-main__slide .slider-unit {
  width: 100%;
  height: 100%;
}

.l-main__slide .slider-unit .slide-item {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: -25vw;
  z-index: 0;
  -webkit-transform: skewX(15deg);
  transform: skewX(15deg);
  outline: 0px none #000;
  overflow: hidden;
}

.l-main__slide .slider-unit .slide-item .slide-img {
  display: block;
  position: absolute;
  top: 0;
  left: 25vw;
  width: 100vw;
  height: 100%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
}

.l-main__slide .slider-unit .slide-item .slide-img > span {
  width: calc(100% + 100px);
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  outline: 0px none #000;
}

.l-main__slide .slider-unit .slide-item .slide-img > span.slide-pc {
  display: block;
}

.l-main__slide .slider-unit .slide-item .slide-img > span.slide-sp {
  display: none;
}

.l-main__slide .slider-unit .slide-item.is-active {
  -webkit-transition: width 0.8s cubic-bezier(0.09, 0.34, 0.63, 1);
  transition: width 0.8s cubic-bezier(0.09, 0.34, 0.63, 1);
  width: 140vw;
}

.l-main__slide .slider-unit .slide-item.is-active .slide-img > span {
  -webkit-transition: -webkit-transform 7s linear;
  transition: -webkit-transform 7s linear;
  transition: transform 7s linear;
  transition: transform 7s linear, -webkit-transform 7s linear;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.l-main__slide .slider-navi {
  list-style: none;
  padding: 0;
  position: absolute;
  right: 2rem;
  position: absolute;
  top: 43.4%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}

.l-main__slide .slider-navi li {
  width: 3.6rem;
  height: 3.6rem;
  cursor: pointer;
  position: relative;
  line-height: 1;
  margin-bottom: .6rem;
  color: #FFF;
}

.l-main__slide .slider-navi li:after {
  position: absolute;
  content: '';
  font-size: 1.2rem;
  left: -9rem;
  top: 1.3rem;
  font-weight: bold;
  text-align: right;
  opacity: .6;
}

.l-main__slide .slider-navi li:nth-child(1):after {
  content: 'FRAGRANCE';
}

.l-main__slide .slider-navi li:nth-child(2):after {
  content: 'OUTDOOR';
  left: -7.8rem;
}

.l-main__slide .slider-navi li:nth-child(3):after {
  content: 'SPORTS';
  left: -6.4rem;
}

.l-main__slide .slider-navi li:last-child {
  margin: 0;
}

.l-main__slide .slider-navi li:before {
  width: .7rem;
  height: .7rem;
  position: relative;
  line-height: 1;
  border-radius: 50%;
  background-color: #FFF;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: .6;
}

.l-main__slide .slider-navi li.is-current:before,
.l-main__slide .slider-navi li.is-current:after {
  opacity: 1;
}

.l-main__slide .slider-navi li.is-current:before {
  background-color: #81c0e9;
}

.l-main__slide .slider-navi li svg {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.l-main__slide .slider-navi li svg .circle {
  fill: none;
  stroke: #81c0e9;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-dashoffset: 246;
  stroke-dasharray: 82 82;
}

.slide-common {
  display: none;
}

/*l-main__scrool*/
.l-main__scroll {
  width: 2rem;
  height: 18rem;
  position: absolute;
  bottom: 4.5rem;
  left: 2.3rem;
  z-index: 10;
}

.scroll-label {
  font-size: 1.3rem;
  font-weight: bold;
  color: #FFF;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  -o-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  position: absolute;
  left: 50%;
  margin-left: .3rem;
  top: 21%;
}

.scroll-line.scroll-line {
  width: .3rem;
  height: auto;
  margin: 3rem auto 0;
  position: absolute;
  left: calc(50% - -.7rem);
  top: 40%;
  z-index: 1;
}

.scroll-line-ob-container {
  position: relative;
  overflow: hidden;
  width: .3rem;
  height: 4rem;
}

.scroll-line-ob {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 75%;
  width: .3rem;
  background-color: #FFF;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.scroll-line .ob01 {
  -webkit-animation: scroll-line 2s linear 0s infinite;
  animation: scroll-line 2s linear 0s infinite;
}

.scroll-line .ob02 {
  -webkit-animation: scroll-line-b 2s linear 1s infinite;
  animation: scroll-line-b 2s linear 1s infinite;
}

@-webkit-keyframes scroll-line {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
  }
}

@keyframes scroll-line {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
  }
}

@-webkit-keyframes scroll-line-b {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
  }
}

@keyframes scroll-line-b {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(150%);
    transform: translateY(150%);
  }
}

/* p-section__index ============================================== */
.p-section__index {
  position: relative;
  z-index: 2;
}

.section-index__flex {
  width: 100%;
  position: relative;
  display: flex;
  pointer-events: auto;
}

.p-section__index-inner {
  position: relative;
}

.p-section__title {
  font-size: 5rem;
  font-weight: bold;
  display: inline-block;
}

.p-top-about .p-section__title {
  font-size: 3rem;
  font-style: italic;
  padding-left: .8rem;
  padding-right: 1rem;
  display: inline-block;
}

.c-b {
  color: #000;
}

.c-w {
  color: #FFF;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

/* footer============================================== */
.l-footer {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #f2f3f4;
  padding: 0 0 0 8rem;
}

@media screen and (max-width: 1024px) {
  /* l-main__visual============================================== */
  .l-main__meta-inner {
    padding: 0 0 0 6rem;
  }
  .l-main__scroll {
    left: 1.3rem;
  }
  /* footer============================================== */
  .l-footer {
    padding: 0 0 0 6rem;
  }
}

@media screen and (max-width: 959px) {
  /* l-main__visual============================================== */
  .l-main__visual {
    height: calc(65rem + 2.5rem);
  }
  .l-main__body {
    height: calc(65rem + 2.5rem);
  }
  .l-main__body-inner {
    height: 100%;
  }
  .l-main__slide {
    height: calc(65rem - 2.5rem);
  }
  .l-main__meta {
    height: 100%;
  }
  .l-main__meta-inner {
    padding: 0 0 0 5rem;
  }
  .copy-first {
    width: 50%;
  }
  .copy-last {
    width: 49.7%;
  }
  .main-meta__read span.msk-set:nth-child(n - 2) {
    margin: 0 0 0.5rem;
  }
  .l-main__scroll {
    bottom: 1.4rem;
    left: 0.8rem;
  }
  .scroll-label {
    font-size: 1.1rem;
    top: 28%;
  }
}

@media screen and (max-width: 769px) {
  .pc-block {
    display: none;
  }
  .sp-block {
    display: block;
  }
  /* l-main__visual============================================== */
  .l-main__visual {
    height: auto;
  }
  .l-main__body {
    height: auto;
  }
  .l-main__body-inner {
    height: auto;
    display: block;
  }
  .l-main__slide {
    height: calc(55rem - 0rem);
  }
  .l-main__slide .slider-unit .slide-item.is-active {
    width: 150vw;
  }
  .l-main__slide .slider-unit .slide-item .slide-img > span.slide-sp {
    display: block;
  }
  .l-main__slide .slider-unit .slide-item .slide-img > span.slide-pc {
    display: none;
  }
  .l-main__slide .slider-navi {
    top: 33.5rem;
    left: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  .l-main__slide .slider-navi li {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 7.5rem 0 0;
  }
  .l-main__slide .slider-navi li {
    display: inline-block;
    -webkit-transform: translateY(110%);
    transform: translateY(110%);
    transition: -webkit-transform 1s cubic-bezier(0.25, 0.82, 0.355, 1);
    transition: transform 1s cubic-bezier(0.25, 0.82, 0.355, 1);
    -webkit-transition-delay: .7s;
    transition-delay: .7s;
  }
  .active .slider-navi li {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  .l-main__slide .slider-navi li:after,
  .l-main__slide .slider-navi li:nth-child(2):after,
  .l-main__slide .slider-navi li:nth-child(3):after {
    top: .35rem;
    left: 2.2rem;
    font-size: 1rem;
  }
  .l-main__meta-inner {
    padding: 0 0 0 2rem;
    top: 13rem;
    transform: translateY(0%);
  }
  .main-meta__copy {
    width: 96%;
  }
  .copy-first {
    width: 89%;
  }
  .copy-last {
    width: 88.7%;
    margin: 0.3rem 0 0 0px;
  }
  .main-meta__read {
    font-size: 1.4rem;
    margin: 1.5rem 0 0;
    letter-spacing: .05em;
  }
  .main-meta__read span {
    display: block;
  }
  .main-meta__read span.msk-set:nth-child(n - 2) {
    margin: 0 0 0.7rem;
  }
  .main-meta__read br {
    display: none;
  }
  .l-main__scroll {
    width: 1rem;
    height: 12rem;
    bottom: 7.8rem;
    left: -.2rem;
    display: none;
  }
  .scroll-label {
    font-size: 1rem;
  }
  .scroll-line.scroll-line {
    width: .2rem;
  }
}

.singleImg img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.itemMainSec > .col-l {
  margin-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .itemMainSec {
    display: flex;
    justify-content: space-between;
  }
  .itemMainSec > .col-l {
    width: 46.55172%;
    margin-bottom: 0;
  }
  .itemMainSec > .col-r {
    width: 43.10345%;
  }
}

.item-detail .item-title {
  font-size: 30px;
  font-size: 4vw;
  font-weight: 500;
  margin-bottom: 2.13333vw;
  position: relative;
}

@media screen and (min-width: 769px) {
  .itemMainSec .item-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 26px;
  }
}

.detailInfo {
  position: relative;
}

.detailInfo .movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.detailInfo .movie iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.carouselStyle .screen {
  position: relative;
  width: 100%;
  height: 100%;
}

.carouselStyle .screen > .carouselItem, .carouselStyle .thumbnail > .carouselItem {
  display: none;
}

.carouselStyle .slick-slide {
  font-size: 0;
}

.carouselStyle button {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.carouselStyle_01 .item img, .carouselStyle_03 .item img {
  display: block;
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
}

.carouselStyle_01 .slick-list, .carouselStyle_03 .slick-list {
  border-radius: 6px;
  border: 1px solid #cfcfcf;
}

.carouselStyle_01 .slick-dots, .carouselStyle_03 .slick-dots {
  width: 80%;
  margin: 0 auto;
  position: relative;
  bottom: auto;
}

.carouselStyle_01 .slick-dots li, .carouselStyle_03 .slick-dots li {
  margin: 0 1.33333vw;
}

.carouselStyle_01 .slick-dots li, .carouselStyle_03 .slick-dots li, .carouselStyle_01 .slick-dots li button, .carouselStyle_03 .slick-dots li button {
  width: 1.86667vw;
  height: 1.86667vw;
}

.carouselStyle_01 .slick-dots li button, .carouselStyle_03 .slick-dots li button {
  padding: 0;
}

.carouselStyle_01 .slick-dots li button:before, .carouselStyle_03 .slick-dots li button:before {
  width: 1.86667vw;
  height: 1.86667vw;
  line-height: 1.86667vw;
}

.carouselStyle_01 .slick-next, .carouselStyle_03 .slick-next, .carouselStyle_01 .slick-prev, .carouselStyle_03 .slick-prev {
  top: auto;
  bottom: -3.33333vw;
}

.carouselStyle_01 .slick-next, .carouselStyle_03 .slick-next {
  right: 8vw;
}

.carouselStyle_01 .slick-prev, .carouselStyle_03 .slick-prev {
  left: 8vw;
}

.carouselStyle_01 .slick-next:after, .carouselStyle_03 .slick-next:after, .carouselStyle_01 .slick-prev:after, .carouselStyle_03 .slick-prev:after {
  display: none;
}

.carouselStyle_01 .slick-next:before, .carouselStyle_03 .slick-next:before, .carouselStyle_01 .slick-prev:before, .carouselStyle_03 .slick-prev:before {
  font-family: "myfont";
  font-size: 20px;
  font-size: 2.66667vw;
  color: #000;
  opacity: 1;
}

.carouselStyle_01 .slick-next:before, .carouselStyle_03 .slick-next:before {
  content: '\EA13';
}

.carouselStyle_01 .slick-prev:before, .carouselStyle_03 .slick-prev:before {
  content: '\EA14';
}

@media screen and (min-width: 769px) {
  .carouselStyle_01 .slick-dots li, .carouselStyle_03 .slick-dots li {
    margin: 0 4px;
  }
  .carouselStyle_01 .slick-dots li, .carouselStyle_03 .slick-dots li, .carouselStyle_01 .slick-dots li button, .carouselStyle_03 .slick-dots li button {
    width: 6px;
    height: 6px;
  }
  .carouselStyle_01 .slick-dots li button:before, .carouselStyle_03 .slick-dots li button:before {
    width: 6px;
    height: 6px;
    line-height: 6px;
  }
  .carouselStyle_01 .slick-next, .carouselStyle_03 .slick-next, .carouselStyle_01 .slick-prev, .carouselStyle_03 .slick-prev {
    bottom: -12px;
  }
  .carouselStyle_01 .slick-next, .carouselStyle_03 .slick-next {
    right: 40px;
  }
  .carouselStyle_01 .slick-prev, .carouselStyle_03 .slick-prev {
    left: 40px;
  }
  .carouselStyle_01 .slick-next:before, .carouselStyle_03 .slick-next:before, .carouselStyle_01 .slick-prev:before, .carouselStyle_03 .slick-prev:before {
    font-size: 1.2rem;
  }
}

.carouselStyle_02 .item img, .carouselStyle_04 .item img {
  display: block;
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
}

.carouselStyle_02 .screen, .carouselStyle_04 .screen {
  margin-bottom: 5.33333vw;
}

.carouselStyle_02 .slick-dots, .carouselStyle_04 .slick-dots {
  display: none;
}

.carouselStyle_02 .slick-next, .carouselStyle_04 .slick-next, .carouselStyle_02 .slick-prev, .carouselStyle_04 .slick-prev {
  display: none;
}

.carouselStyle_02 .slick-next:after, .carouselStyle_04 .slick-next:after, .carouselStyle_02 .slick-prev:after, .carouselStyle_04 .slick-prev:after {
  display: none;
}

.carouselStyle_02 .slick-next:before, .carouselStyle_04 .slick-next:before, .carouselStyle_02 .slick-prev:before, .carouselStyle_04 .slick-prev:before {
  display: none;
}

.carouselStyle_02 .thumbnail, .carouselStyle_04 .thumbnail {
  margin-left: -1.06667vw;
  margin-right: -1.06667vw;
}

.carouselStyle_02 .thumbnail .slick-slide, .carouselStyle_04 .thumbnail .slick-slide {
  opacity: .7;
  cursor: pointer;
  transition: .3s;
}

.carouselStyle_02 .thumbnail .slick-current, .carouselStyle_04 .thumbnail .slick-current {
  opacity: 1;
  cursor: default;
  pointer-events: none;
}

.carouselStyle_02 .thumbnail .item, .carouselStyle_04 .thumbnail .item {
  padding: 0 1.06667vw;
}

@media screen and (min-width: 769px) {
  .carouselStyle_02 .screen, .carouselStyle_04 .screen {
    margin-bottom: 20px;
  }
  .carouselStyle_02 .slick-next, .carouselStyle_04 .slick-next, .carouselStyle_02 .slick-prev, .carouselStyle_04 .slick-prev {
    display: inline-block;
    width: 47px;
    height: 47px;
    background-color: #000;
    text-align: center;
    border-radius: 100%;
    transition: .3s;
  }
  .carouselStyle_02 .slick-next:hover, .carouselStyle_04 .slick-next:hover, .carouselStyle_02 .slick-prev:hover, .carouselStyle_04 .slick-prev:hover {
    background-color: #404241;
  }
  .carouselStyle_02 .slick-next, .carouselStyle_04 .slick-next {
    right: -23px;
  }
  .carouselStyle_02 .slick-prev, .carouselStyle_04 .slick-prev {
    left: -23px;
  }
  .carouselStyle_02 .slick-next:after, .carouselStyle_04 .slick-next:after, .carouselStyle_02 .slick-prev:after, .carouselStyle_04 .slick-prev:after {
    display: none;
  }
  .carouselStyle_02 .slick-next:before, .carouselStyle_04 .slick-next:before, .carouselStyle_02 .slick-prev:before, .carouselStyle_04 .slick-prev:before {
    display: inline-block;
    font-family: "myfont";
    font-size: 1.6rem;
    color: #fff;
    opacity: 1;
    line-height: 47px;
  }
  .carouselStyle_02 .slick-next:before, .carouselStyle_04 .slick-next:before {
    content: '\EA13';
  }
  .carouselStyle_02 .slick-prev:before, .carouselStyle_04 .slick-prev:before {
    content: '\EA14';
  }
  .carouselStyle_02 .thumbnail, .carouselStyle_04 .thumbnail {
    margin-left: -7px;
    margin-right: -7px;
  }
  .carouselStyle_02 .thumbnail .slick-slide:hover, .carouselStyle_04 .thumbnail .slick-slide:hover {
    opacity: .9;
  }
  .carouselStyle_02 .thumbnail .item, .carouselStyle_04 .thumbnail .item {
    padding: 0 7px;
  }
}

.carouselStyle_03 .slick-list {
  border-radius: 0px;
  border: none;
}

.carouselStyle_03 .slick-next:before, .carouselStyle_03 .slick-prev:before {
  color: #fff;
}

.carouselStyle_03 .slick-dots li.slick-active button:before {
  color: #fff;
}

.carouselStyle_03 .slick-dots li button:before {
  color: #fff;
}

.carouselStyle_04 .item img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.inputStyle_01, .inputStyle_02, .inputStyle_03 {
  width: 100%;
  position: relative;
}

.inputStyle_01 .btnSubmit, .inputStyle_02 .btnSubmit, .inputStyle_03 .btnSubmit {
  color: #a5a5a5;
  line-height: 1em;
  font-size: 1.6rem;
  position: absolute;
  right: 10px;
  top: calc(50% - .8rem);
  display: block;
  cursor: pointer;
}

.inputStyle_01 input, .inputStyle_02 input, .inputStyle_03 input {
  width: 100%;
  background-color: #efefef;
  border: 1px solid #efefef;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: none;
  border-radius: 2px;
  outline: none;
  font-size: 1.3rem;
  line-height: 1em;
  min-height: 38px;
  transition: .3s;
  padding-left: 8px;
  padding-right: 36px;
  /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  /* Google Chrome, Safari, Opera 15+, Android, iOS */
  /* Firefox 19+ */
}

.inputStyle_01 input[type="number"]::-webkit-outer-spin-button, .inputStyle_02 input[type="number"]::-webkit-outer-spin-button, .inputStyle_03 input[type="number"]::-webkit-outer-spin-button, .inputStyle_01 input[type="number"]::-webkit-inner-spin-button, .inputStyle_02 input[type="number"]::-webkit-inner-spin-button, .inputStyle_03 input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputStyle_01 input[type="number"], .inputStyle_02 input[type="number"], .inputStyle_03 input[type="number"] {
  -moz-appearance: textfield;
}

.inputStyle_01 input:focus, .inputStyle_02 input:focus, .inputStyle_03 input:focus {
  background-color: #fff;
  border: 1px solid #000;
}

.inputStyle_01 input:placeholder-shown, .inputStyle_02 input:placeholder-shown, .inputStyle_03 input:placeholder-shown {
  color: #a5a5a5;
}

.inputStyle_01 input::-webkit-input-placeholder, .inputStyle_02 input::-webkit-input-placeholder, .inputStyle_03 input::-webkit-input-placeholder {
  color: #a5a5a5;
}

.inputStyle_01 input::-moz-placeholder, .inputStyle_02 input::-moz-placeholder, .inputStyle_03 input::-moz-placeholder {
  color: #a5a5a5;
}

.inputStyle_02 .btnSubmit {
  font-size: 40px;
  font-size: 5.33333vw;
  right: 2.66667vw;
  top: calc(50% - 2.66667vw);
}

.inputStyle_02 input {
  border: 1px solid #cfcfcf;
  min-height: 14.66667vw;
  padding-left: 2.66667vw;
  padding-right: 11.33333vw;
  font-size: 32px;
  font-size: 4.26667vw;
  /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  /* Google Chrome, Safari, Opera 15+, Android, iOS */
  /* Firefox 19+ */
}

.inputStyle_02 input:placeholder-shown {
  font-size: 32px;
  font-size: 4.26667vw;
}

.inputStyle_02 input::-webkit-input-placeholder {
  font-size: 32px;
  font-size: 4.26667vw;
}

.inputStyle_02 input::-moz-placeholder {
  font-size: 32px;
  font-size: 4.26667vw;
}

@media screen and (min-width: 769px) {
  .inputStyle_02 .btnSubmit {
    font-size: 1.6rem;
    right: 10px;
    top: calc(50% - .8rem);
  }
  .inputStyle_02 input {
    min-height: 50px;
    padding-left: 12px;
    padding-right: 36px;
    font-size: 1.7rem;
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
    /* Google Chrome, Safari, Opera 15+, Android, iOS */
    /* Firefox 19+ */
  }
  .inputStyle_02 input:placeholder-shown {
    font-size: 1.7rem;
  }
  .inputStyle_02 input::-webkit-input-placeholder {
    font-size: 1.7rem;
  }
  .inputStyle_02 input::-moz-placeholder {
    font-size: 1.7rem;
  }
}

.inputStyle_03 input {
  border: 1px solid #cfcfcf;
  min-height: 14.66667vw;
  padding-left: 2.66667vw;
  padding-right: 11.33333vw;
  font-size: 32px;
  font-size: 4.26667vw;
  /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  /* Google Chrome, Safari, Opera 15+, Android, iOS */
  /* Firefox 19+ */
}

.inputStyle_03 input:placeholder-shown {
  font-size: 32px;
  font-size: 4.26667vw;
}

.inputStyle_03 input::-webkit-input-placeholder {
  font-size: 32px;
  font-size: 4.26667vw;
}

.inputStyle_03 input::-moz-placeholder {
  font-size: 32px;
  font-size: 4.26667vw;
}

@media screen and (min-width: 769px) {
  .inputStyle_03 input {
    min-height: 60px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 1.7rem;
    /* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
    /* Google Chrome, Safari, Opera 15+, Android, iOS */
    /* Firefox 19+ */
  }
  .inputStyle_03 input:placeholder-shown {
    font-size: 1.7rem;
  }
  .inputStyle_03 input::-webkit-input-placeholder {
    font-size: 1.7rem;
  }
  .inputStyle_03 input::-moz-placeholder {
    font-size: 1.7rem;
  }
}

.selectStyle_01 {
  position: relative;
  transition: .3s;
}

.selectStyle_01:before {
  font-family: "myfont";
  content: "\EA12";
  position: absolute;
  line-height: 0;
  top: calc(50% - .8rem);
  right: 2.66667vw;
  color: #000;
  z-index: 10;
  font-size: 30px;
  font-size: 4vw;
  line-height: 1em;
}

.selectStyle_01 select {
  transition: .3s;
  width: 100%;
  position: relative;
  z-index: 2;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 32px;
  font-size: 4.26667vw;
  border-radius: 2px;
  background-color: #efefef;
  padding: 0 2.66667vw;
  min-height: 14.66667vw;
  outline: 0;
  border: 1px solid #cfcfcf;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
  color: #2a2a2a;
}

.selectStyle_01 select:focus {
  border: 1px solid #000;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
  outline: 0;
  background-color: transparent;
}

.selectStyle_01 select::-ms-expand {
  display: none;
}

.selectStyle_01 option {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .selectStyle_01 {
    background-color: #efefef;
  }
  .selectStyle_01:before {
    right: 20px;
    z-index: 1;
    font-size: 1.6rem;
  }
  .selectStyle_01 select {
    font-size: 1.7rem;
    background-color: transparent;
    padding: 0 12px;
    min-height: 60px;
  }
}

.formColStyle_01 {
  display: flex;
  align-items: center;
}

.formColStyle_01 .col {
  width: 32vw;
}

.formColStyle_01 .sep {
  font-size: 32px;
  font-size: 4.26667vw;
  padding: 0 1.33333vw;
  font-weight: 600;
}

.formColStyle_01 .label {
  font-size: 30px;
  font-size: 4vw;
  font-weight: 600;
  padding-left: 1.33333vw;
  margin-top: auto;
}

@media screen and (min-width: 769px) {
  .formColStyle_01 .col {
    width: 38.11881%;
  }
  .formColStyle_01 .sep {
    font-size: 1.7rem;
    padding: 0 14px;
  }
  .formColStyle_01 .label {
    font-size: 1.8rem;
    padding-left: 8px;
  }
}

#belmoContent .category-title {
  justify-content: flex-end;
}

#belmoContent .breadcrumb {
  padding: 18.66667vw 2.66667vw 1.33333vw;
  background-color: #888;
  color: #fff;
  margin-bottom: 0;
}

.hasNews #belmoContent .breadcrumb {
  padding: 25.33333vw 2.66667vw 1.33333vw;
}

#belmoContent .breadcrumb a {
  color: #fff;
}

@media screen and (min-width: 769px) {
  #belmoContent .breadcrumb {
    padding: 84px 24px 10px;
  }
  .hasNews #belmoContent .breadcrumb {
    padding: 118px 24px 10px;
  }
}

#belmoContent .pager-wrap a {
  position: relative;
  overflow: hidden;
  position: relative;
}

#belmoContent .pager-wrap a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #2a2a2a;
}

@media screen and (min-width: 769px) {
  #belmoContent .pager-wrap a:hover:before {
    width: 100%;
  }
}

#belmoContent .pager-wrap span.current {
  font-weight: bold;
  text-decoration: underline;
}

#belmoContent .cart-button-wrap .cart-button a {
  border-radius: 3px;
  background-color: #aacc03;
}

#belmoContent .guide-section {
  width: 100%;
  padding-top: 18.66667vw;
}

@media screen and (min-width: 769px) {
  #belmoContent .guide-section {
    width: 100%;
    padding-top: 110px;
  }
}

#belmoContent .contract-section, #belmoContent .contract-section {
  width: 100%;
  margin-bottom: 6.66667vw;
}

@media screen and (min-width: 769px) {
  #belmoContent .contract-section, #belmoContent .contract-section {
    width: 100%;
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 769px) {
  #belmoContent .contract-section dt {
    font-size: 1.8rem;
  }
}

#belmoContent .price {
  font-size: 30px;
  font-size: 4vw;
  line-height: 1em;
  font-weight: 600;
}

#belmoContent .price span.tax {
  font-size: 20px;
  font-size: 2.66667vw;
}

#belmoContent .price span.yenIcon {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
  padding-right: 0.8vw;
}

@media screen and (min-width: 769px) {
  #belmoContent .price {
    font-size: 1.6rem;
  }
  #belmoContent .price span.tax {
    font-size: 1.1rem;
  }
  #belmoContent .price span.yenIcon {
    font-size: 1.3rem;
    padding-right: 3px;
  }
}

#belmoContent .sale-discount-rate {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
}

@media screen and (min-width: 769px) {
  #belmoContent .sale-discount-rate {
    font-size: 1.3rem;
  }
}

#belmoContent .original-price {
  text-decoration: none;
  position: relative;
  display: inline-block;
}

#belmoContent .original-price::after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 0;
}

#belmoContent .comapany-box {
  width: 100%;
}

#belmoContent .guide-section .preSec b {
  font-weight: bold;
  font-size: 30px;
  font-size: 4vw;
  margin-bottom: 2.66667vw;
  display: block;
}

#belmoContent .guide-section .preSec h3 {
  font-size: 32px;
  font-size: 4.26667vw;
  line-height: 1em;
  font-weight: 600;
  border-left: 4px solid #a5a5a5;
  padding-left: 2.66667vw;
  margin-top: 6.66667vw;
}

#belmoContent .guide-section .preSec h3:first-child {
  margin-top: 0;
}

#belmoContent .guide-section .preSec > * {
  margin-bottom: 4vw;
}

#belmoContent .guide-section .preSec > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  #belmoContent .guide-section .preSec b {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  #belmoContent .guide-section .preSec h3 {
    font-size: 1.8rem;
    padding-left: 10px;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  #belmoContent .guide-section .preSec h3:first-child {
    margin-top: 0;
  }
  #belmoContent .guide-section .preSec > * {
    margin-bottom: 20px;
  }
  #belmoContent .guide-section .preSec > *:last-child {
    margin-bottom: 0;
  }
}

.cart-count {
  position: absolute;
  right: 0vw;
  top: -0.8vw;
  background: #b30000;
  color: #fff;
  width: 4vw;
  height: 4vw;
  line-height: 4vw;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  font-size: 2.66667vw;
  font-weight: normal;
}

.navStyle_02 .cart-count, .navStyle_03 .cart-count {
  display: none;
}

@media screen and (min-width: 769px) {
  .cart-count {
    position: absolute;
    right: -8px;
    top: -8px;
    background: #b30000;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 1.2rem;
    font-weight: normal;
  }
}

.add-cart .btn {
  border-radius: 3px;
}

.add-cart .add-cart-btn {
  background-color: #aacc03;
}

#headLine {
  background-color: #000;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

#headLine article {
  height: 6.66667vw;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#headLine a {
  color: #fff;
  line-height: 1em;
  position: relative;
}

#headLine a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #fff;
}

@media screen and (min-width: 769px) {
  #headLine a:hover:before {
    width: 100%;
  }
}

#headLine a h2 {
  font-size: 20px;
  font-size: 2.66667vw;
  font-weight: normal;
  margin-bottom: 0;
}

.menuOpen #headLine {
  display: none;
}

@media screen and (min-width: 769px) {
  #headLine article {
    height: 34px;
  }
  #headLine a h2 {
    font-size: 1.1rem;
  }
}

header {
  width: 100%;
  padding: 0;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #e5e5e5;
}

.home header {
  border: none;
}

.hasNews header {
  top: 6.66667vw;
}

header > .inner {
  display: flex;
  height: 16vw;
  align-items: center;
  justify-content: space-between;
}

.menuOpen header > .inner {
  justify-content: flex-end;
}

@media screen and (min-width: 769px) {
  .hasNews header {
    top: 34px;
  }
  header > .inner {
    height: 72px;
  }
}

header .col-logo {
  padding: 0 2.66667vw;
  box-sizing: content-box;
  height: 100%;
  display: flex;
  align-items: center;
}

header .col-logo img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  width: 42.13333vw;
}

.menuOpen header .col-logo {
  display: none;
}

@media screen and (min-width: 769px) {
  header .col-logo {
    padding: 0 24px;
    border-right: 1px solid #e5e5e5;
  }
  header .col-logo img {
    width: 209px;
  }
}

header .col-input {
  display: none;
}

@media screen and (min-width: 960px) {
  header .col-input {
    display: block;
    width: 100%;
    padding: 0 14px;
    border-right: 1px solid #e5e5e5;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .menuOpen header .col-input {
    display: none;
  }
}

header .col-nav {
  width: 100%;
  padding: 2.66667vw 0px;
}

.menuOpen header .col-nav {
  display: none;
}

header .col-nav .inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (min-width: 769px) {
  header .col-nav {
    width: auto;
    max-width: 590px;
    min-width: 500px;
    padding: 16px 20px;
  }
}

.gNav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.gNav li a {
  width: 13.6vw;
  text-align: center;
  display: block;
  position: relative;
}

.gNav li a > span {
  display: block;
}

.gNav li.spHide {
  display: none;
}

@media screen and (min-width: 769px) {
  .gNav {
    justify-content: space-between;
  }
  .gNav li a {
    width: auto;
    padding: 0 5px;
  }
  .gNav li.spHide {
    display: block;
  }
}

header .labelTx {
  font-size: 20px;
  font-size: 2.66667vw;
  line-height: 1em;
  white-space: nowrap;
}

header .labelIcon {
  font-size: 36px;
  font-size: 4.8vw;
  line-height: 1em;
  margin-bottom: 1.33333vw;
}

@media screen and (min-width: 769px) {
  header .labelTx {
    font-size: 1.0rem;
  }
  header .labelIcon {
    font-size: 2.4rem;
    margin-bottom: 6px;
  }
}

.col-menu {
  border-left: 1px solid #e5e5e5;
  height: 100%;
  display: flex;
  align-items: center;
}

.menuOpen .col-menu {
  border: none;
}

#menuBtn {
  cursor: pointer;
  width: 72px;
}

#menuBtn .iconCel {
  height: 2.4rem;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 769px) {
  #menuBtn {
    cursor: pointer;
    width: 72px;
  }
  #menuBtn .iconCel {
    height: 2.4rem;
    display: flex;
    align-items: center;
  }
}

#menuBtn .burger {
  width: 5.73333vw;
  height: 2.8vw;
  position: relative;
  margin: 0 auto;
  top: 2px;
}

#menuBtn .burger > span {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #2a2a2a;
  position: absolute;
  left: 0;
  transition: .3s;
}

#menuBtn .burger > span:nth-child(1) {
  top: 0;
}

#menuBtn .burger > span:nth-child(2) {
  bottom: 0;
}

#menuBtn.active .burger > span:nth-child(1) {
  transform: translateY(2.4vw) rotate(45deg);
}

#menuBtn.active .burger > span:nth-child(2) {
  transform: translateY(0vw) rotate(-45deg);
}

@media screen and (min-width: 769px) {
  #menuBtn .burger {
    width: 23px;
    height: 13px;
  }
  #menuBtn.active .burger > span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }
  #menuBtn.active .burger > span:nth-child(2) {
    transform: translateY(-2px) rotate(-45deg);
  }
}

footer {
  width: 100%;
  padding: 17.33333vw 2.66667vw;
  background-color: #000;
  color: #fff;
}

footer a {
  color: #fff;
}

footer > .inner {
  width: 100%;
  max-width: 78.66667vw;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  footer {
    height: 658px;
    padding: 80px;
    display: flex;
    align-items: center;
  }
  footer > .inner {
    max-width: 1160px;
  }
}

@media screen and (min-width: 769px) {
  .footColumn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footColumn .col.style_01 {
    width: 20.45455vw;
    max-width: 270px;
  }
  .footColumn .col.style_02 {
    width: 396px;
  }
  .footColumn .col.style_03 {
    width: 21.59091vw;
    max-width: 285px;
  }
}

.footLogo {
  margin-bottom: 7.73333vw;
}

.footLogo svg {
  fill: #fff;
  width: 18.4vw;
  height: 17.6vw;
}

@media screen and (min-width: 769px) {
  .footLogo {
    margin-bottom: 26px;
  }
  .footLogo svg {
    width: 92px;
    height: 89px;
  }
}

.footInfo {
  margin-top: 6.4vw;
  padding-top: 6.4vw;
  border-top: 1px solid #434343;
}

.footInfo .linkStyle_01, .footInfo .linkStyle_02, .footInfo .linkStyle_03 {
  color: #fff;
}

.footInfo .linkStyle_01::before, .footInfo .linkStyle_02::before, .footInfo .linkStyle_03::before {
  background-color: #fff;
}

.footInfo .linkStyle_03 {
  color: #b9b8b8;
}

.footInfo .linkStyle_03::before {
  background-color: #b9b8b8;
}

.footInfo .linkStyle_04 .labelTx::before {
  background-color: #fff;
}

@media screen and (min-width: 769px) {
  .footInfo {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
}

.footNavWrap {
  margin-top: 6.4vw;
  padding-top: 6.4vw;
  border-top: 1px solid #434343;
}

@media screen and (min-width: 769px) {
  .footNavWrap {
    margin-top: 22px;
    padding-top: 22px;
  }
}

.copyRightSec {
  padding-top: 16vw;
}

.copyRightSec small {
  display: block;
  text-align: center;
  color: #b9b8b8;
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
  font-weight: 500;
}

@media screen and (min-width: 769px) {
  .copyRightSec {
    padding-top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 38px;
  }
  .copyRightSec small {
    display: block;
    text-align: center;
    color: #b9b8b8;
    font-size: 1.0rem;
    line-height: 1em;
    font-weight: 500;
  }
}

i {
  vertical-align: top;
}

i:before {
  vertical-align: top;
}

.lazyload {
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.lazyloaded {
  opacity: 1;
}

.lazyloaded + .loadImg {
  display: none !important;
}

/*.lazyload, .lazyloading {
 opacity: 0;
}
.lazyloaded{
  opacity: 1;
  transition: all 0.5s;
}*/
.way, .safari .luxy-el {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.way.is-active, .showFlag .way, .safari .luxy-el.is-active, .showFlag .safari .luxy-el {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.autoWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .autoWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

@media screen and (min-width: 769px) {
  .autoWay {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .loaded .autoWay {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
  }
  .autoWay.active {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.fastWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fastWay.active, .showContFlag .fastWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.alphaWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition-delay: 0s;
  -moz-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.alphaWay.is-active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.scaleWay {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 0.9s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.scaleWay.active {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.imgEffect_01 > span, .imgEffect_02 > span {
  display: block;
  transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow: hidden;
  position: relative;
  left: -100%;
  opacity: 0;
}

.imgEffect_01 > span img, .imgEffect_02 > span img {
  position: relative;
  left: 100%;
  transition: 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.imgEffect_01.is-active > span, .is-active.imgEffect_02 > span {
  left: 0;
  opacity: 1;
}

.imgEffect_01.is-active > span img, .is-active.imgEffect_02 > span img {
  left: 0;
}

.imgEffect_02 > span {
  left: auto;
  right: -100%;
}

.imgEffect_02 > span img {
  left: auto;
  right: 100%;
}

.imgEffect_02.is-active > span {
  left: auto;
  right: 0;
}

.imgEffect_02.is-active > span img {
  left: auto;
  right: 0;
}

.imgEffect_03 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.imgEffect_03.is-active {
  opacity: 1;
  transform: translateY(0);
}

.imgEffect_04 {
  overflow: hidden;
  position: relative;
}

.imgEffect_04::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.is-active .imgEffect_04::before {
  animation: 0.6s slideOutRight cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s forwards;
}

.imgEffect_05 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.is-active .imgEffect_05 {
  opacity: 1;
  transform: translateY(0);
}

.imgEffect_06 {
  display: block;
  opacity: 0;
  transform: translateY(30);
  transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
}

.imgEffect_06.is-active {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (min-width: 769px) {
  .imgEffect_06 {
    opacity: 1;
    transform: translateY(0);
  }
}

.txEffect_01 {
  position: relative;
}

.txEffect_01 > span > span {
  position: relative;
  display: inline-block;
  opacity: 0;
  top: 1em;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  letter-spacing: .02em;
}

.qualitas-nihonbashi-hamacho .txEffect_01 > span > span {
  letter-spacing: 0em;
}

.is-active .txEffect_01 > span > span {
  opacity: 1;
  top: 0;
}

.txEffect_01 > span > span:nth-child(1) {
  transition-delay: 0.02s;
}

.txEffect_01 > span > span:nth-child(2) {
  transition-delay: 0.04s;
}

.txEffect_01 > span > span:nth-child(3) {
  transition-delay: 0.06s;
}

.txEffect_01 > span > span:nth-child(4) {
  transition-delay: 0.08s;
}

.txEffect_01 > span > span:nth-child(5) {
  transition-delay: 0.1s;
}

.txEffect_01 > span > span:nth-child(6) {
  transition-delay: 0.12s;
}

.txEffect_01 > span > span:nth-child(7) {
  transition-delay: 0.14s;
}

.txEffect_01 > span > span:nth-child(8) {
  transition-delay: 0.16s;
}

.txEffect_01 > span > span:nth-child(9) {
  transition-delay: 0.18s;
}

.txEffect_01 > span > span:nth-child(10) {
  transition-delay: 0.2s;
}

.txEffect_01 > span > span:nth-child(11) {
  transition-delay: 0.22s;
}

.txEffect_01 > span > span:nth-child(12) {
  transition-delay: 0.24s;
}

.txEffect_01 > span > span:nth-child(13) {
  transition-delay: 0.26s;
}

.txEffect_01 > span > span:nth-child(14) {
  transition-delay: 0.28s;
}

.txEffect_01 > span > span:nth-child(15) {
  transition-delay: 0.3s;
}

.txEffect_01 > span > span:nth-child(16) {
  transition-delay: 0.32s;
}

.txEffect_01 > span > span:nth-child(17) {
  transition-delay: 0.34s;
}

.txEffect_01 > span > span:nth-child(18) {
  transition-delay: 0.36s;
}

.txEffect_01 > span > span:nth-child(19) {
  transition-delay: 0.38s;
}

.txEffect_01 > span > span:nth-child(20) {
  transition-delay: 0.4s;
}

.txEffect_01 > span > span:nth-child(21) {
  transition-delay: 0.42s;
}

.txEffect_01 > span > span:nth-child(22) {
  transition-delay: 0.44s;
}

.txEffect_01 > span > span:nth-child(23) {
  transition-delay: 0.46s;
}

.txEffect_01 > span > span:nth-child(24) {
  transition-delay: 0.48s;
}

.txEffect_01 > span > span:nth-child(25) {
  transition-delay: 0.5s;
}

.txEffect_01 > span > span:nth-child(26) {
  transition-delay: 0.52s;
}

.txEffect_01 > span > span:nth-child(27) {
  transition-delay: 0.54s;
}

.txEffect_01 > span > span:nth-child(28) {
  transition-delay: 0.56s;
}

.txEffect_01 > span > span:nth-child(29) {
  transition-delay: 0.58s;
}

.txEffect_01 > span > span:nth-child(30) {
  transition-delay: 0.6s;
}

.txEffect_01 > span > span:nth-child(31) {
  transition-delay: 0.62s;
}

.txEffect_01 > span > span:nth-child(32) {
  transition-delay: 0.64s;
}

.txEffect_01 > span > span:nth-child(33) {
  transition-delay: 0.66s;
}

.txEffect_01 > span > span:nth-child(34) {
  transition-delay: 0.68s;
}

.txEffect_01 > span > span:nth-child(35) {
  transition-delay: 0.7s;
}

.txEffect_01 > span > span:nth-child(36) {
  transition-delay: 0.72s;
}

.txEffect_01 > span > span:nth-child(37) {
  transition-delay: 0.74s;
}

.txEffect_01 > span > span:nth-child(38) {
  transition-delay: 0.76s;
}

.txEffect_01 > span > span:nth-child(39) {
  transition-delay: 0.78s;
}

.txEffect_01 > span > span:nth-child(40) {
  transition-delay: 0.8s;
}

.txEffect_01 > span > span:nth-child(41) {
  transition-delay: 0.82s;
}

.txEffect_01 > span > span:nth-child(42) {
  transition-delay: 0.84s;
}

.txEffect_01 > span > span:nth-child(43) {
  transition-delay: 0.86s;
}

.txEffect_01 > span > span:nth-child(44) {
  transition-delay: 0.88s;
}

.txEffect_01 > span > span:nth-child(45) {
  transition-delay: 0.9s;
}

.txEffect_01 > span > span:nth-child(46) {
  transition-delay: 0.92s;
}

.txEffect_01 > span > span:nth-child(47) {
  transition-delay: 0.94s;
}

.txEffect_01 > span > span:nth-child(48) {
  transition-delay: 0.96s;
}

.txEffect_01 > span > span:nth-child(49) {
  transition-delay: 0.98s;
}

.txEffect_01 > span > span:nth-child(50) {
  transition-delay: 1s;
}

.txEffect_01 > span > span:nth-child(51) {
  transition-delay: 1.02s;
}

.txEffect_01 > span > span:nth-child(52) {
  transition-delay: 1.04s;
}

.txEffect_01 > span > span:nth-child(53) {
  transition-delay: 1.06s;
}

.txEffect_01 > span > span:nth-child(54) {
  transition-delay: 1.08s;
}

.txEffect_01 > span > span:nth-child(55) {
  transition-delay: 1.1s;
}

.txEffect_01 > span > span:nth-child(56) {
  transition-delay: 1.12s;
}

.txEffect_01 > span > span:nth-child(57) {
  transition-delay: 1.14s;
}

.txEffect_01 > span > span:nth-child(58) {
  transition-delay: 1.16s;
}

.txEffect_01 > span > span:nth-child(59) {
  transition-delay: 1.18s;
}

.txEffect_01 > span > span:nth-child(60) {
  transition-delay: 1.2s;
}

.hiddenParts {
  display: none;
}

@keyframes linemove_01 {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  50.01% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.grecaptcha-badge {
  display: none;
  bottom: 26.66667vw !important;
}

.page-template-page-order .grecaptcha-badge, .page-template-page-contact .grecaptcha-badge, .page-template-page-trial .grecaptcha-badge {
  display: block;
}

@media screen and (min-width: 769px) {
  .grecaptcha-badge {
    bottom: 100px !important;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.linkOff {
  cursor: default;
  pointer-events: none;
}

.bx-wrapper {
  display: none;
}

#outerMenu {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  position: relative;
}

.menuOpen #outerMenu, .modalOpen #outerMenu {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#clickBlocker {
  width: 100%;
  height: 0%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.menuOpen #clickBlocker {
  width: 100%;
  height: 100%;
  z-index: 600;
}

#menuPanel {
  height: 0;
  opacity: 0;
  position: fixed;
  z-index: 0;
  transition: .6s;
  width: 100%;
  overflow: hidden;
  transition: height 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.menuOpen #menuPanel {
  height: auto;
  opacity: 1;
  z-index: 900;
  width: 100%;
  position: relative;
}

#menuPanel > .wrapper {
  position: relative;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5.33333vw;
}

.menuOpen #menuPanel > .wrapper {
  min-height: 100vh;
}

#menuPanel > .wrapper > .inner {
  width: 100%;
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  padding: 24vw 0;
}

@media screen and (min-width: 769px) {
  #menuPanel > .wrapper {
    padding: 0 80px;
  }
  #menuPanel > .wrapper > .inner {
    padding: 115px 0;
  }
}

#siteMenu {
  position: relative;
  width: 100%;
}

.siteMenuLogo {
  width: 66.8vw;
  margin: 0 auto 5.33333vw;
}

.siteMenuLogo img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

@media screen and (min-width: 769px) {
  .siteMenuLogo {
    width: auto;
    margin: 0;
  }
  .siteMenuLogo img {
    max-width: none;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
}

.headSec {
  margin-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .headSec {
    margin-bottom: 34px;
  }
}

@media screen and (min-width: 769px) {
  .siteMenuSec {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .siteMenuSec .col.style_01 {
    width: 22.12121vw;
    max-width: 292px;
  }
  .siteMenuSec .col.style_02 {
    width: 30.30303vw;
    max-width: 400px;
  }
  .siteMenuSec .col.style_03 {
    width: 18.93939vw;
    max-width: 250px;
  }
}

.secStyle_01 {
  position: relative;
  background-color: #f2f3f4;
  padding-left: 2.66667vw;
  padding-right: 2.66667vw;
}

.bg_01 .secStyle_01:nth-child(2n) {
  background-color: #fff;
}

.secStyle_01 > .inner {
  opacity: 0;
  position: relative;
  top: 10px;
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.secStyle_01.is-active > .inner {
  opacity: 1;
  top: 0;
}

@media screen and (min-width: 769px) {
  .secStyle_01 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.secStyle_02 {
  position: relative;
  padding-left: 2.66667vw;
  padding-right: 2.66667vw;
}

.bg_02 .secStyle_02 {
  border-top: 1px solid #e5e5e5;
}

.secStyle_02.style_01 {
  border-top: 1px solid #e5e5e5;
}

.secStyle_02 > .inner {
  opacity: 0;
  transform: translateY(10px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.secStyle_02.is-active > .inner {
  opacity: 1;
  transform: translateY(0px);
}

@media screen and (min-width: 769px) {
  .secStyle_02 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.secStyle_03 {
  position: relative;
  padding-left: 2.66667vw;
  padding-right: 2.66667vw;
  background-color: #000;
  color: #fff;
  overflow: hidden;
}

.secStyle_03 .bgImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
}

.secStyle_03 .bgImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .2;
}

.secStyle_03 > .inner {
  position: relative;
  opacity: 0;
  transform: translateY(10px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.secStyle_03.is-active > .inner {
  opacity: 1;
  transform: translateY(0px);
}

@media screen and (min-width: 769px) {
  .secStyle_03 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.secStyle_04 {
  position: relative;
  background-color: #000;
  padding-left: 2.66667vw;
  padding-right: 2.66667vw;
  color: #fff;
}

@media screen and (min-width: 769px) {
  .secStyle_04 {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.articleWrap_01 {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.articleWrap_01 > * {
  width: 50%;
}

.articleWrap_01 > *:nth-child(n+3) {
  margin-top: 2.66667vw;
}

.articleWrap_01.ranking {
  counter-reset: number 0;
}

.articleWrap_01.ranking > * > a::before {
  counter-increment: number 1;
  content: counter(number);
}

.articleWrap_01.style_02 > *:nth-child(n+3) {
  display: none;
}

@media screen and (min-width: 769px) {
  .articleWrap_01 > * {
    width: 20%;
  }
  .articleWrap_01 > *:nth-child(n+3) {
    margin-top: 0;
  }
  .articleWrap_01 > *:nth-child(n+6) {
    margin-top: 40px;
  }
  .articleWrap_01.style_01 > *:nth-child(n+6) {
    display: none;
  }
  .articleWrap_01.style_02 > *:nth-child(n+3) {
    display: block;
  }
}

.colStyle_01 .col-main {
  margin-bottom: 11.73333vw;
}

@media screen and (min-width: 769px) {
  .colStyle_01 {
    display: flex;
    justify-content: space-between;
  }
  .colStyle_01 .col-main {
    width: 72.41379%;
    margin-bottom: 0;
  }
  .colStyle_01 .col-side {
    width: 261px;
  }
}

.colStyle_02 > * {
  margin-bottom: 5.33333vw;
}

@media screen and (min-width: 769px) {
  .colStyle_02 {
    display: flex;
    justify-content: space-between;
  }
  .colStyle_02 > * {
    width: 48.09524%;
    margin-bottom: 0;
  }
}

.colStyle_03 .col-main {
  margin-bottom: 11.46667vw;
}

@media screen and (min-width: 769px) {
  .colStyle_03 {
    display: flex;
    justify-content: space-between;
  }
  .colStyle_03 .col-main {
    width: 56.28684%;
    margin-bottom: 0;
  }
  .colStyle_03 .col-side {
    width: 33.39882%;
  }
}

.elemWrapStyle_01 {
  text-align: center;
}

.elemWrapStyle_01 > * {
  display: inline-block;
  min-width: 48vw;
}

@media screen and (min-width: 769px) {
  .elemWrapStyle_01 {
    text-align: left;
  }
  .elemWrapStyle_01 > * {
    min-width: 200px;
  }
}

@media screen and (min-width: 769px) {
  .setW_01 {
    max-width: 1160px;
    margin: 0 auto;
  }
}

.setW_02 {
  max-width: 89.06667vw;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .setW_02 {
    max-width: 1018px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px) {
  .setW_03 {
    max-width: 755px;
    margin: 0 auto;
  }
}

#wrapper {
  color: #2a2a2a;
}

#mainVis {
  margin-top: 22.66667vw;
  position: relative;
  background-color: #000;
}

@media screen and (min-width: 769px) {
  #mainVis {
    margin-top: 106px;
  }
}

#mainVis .l-main__slide {
  opacity: 0;
  transition: .8s;
  height: calc(100vh - 50.66667vw);
}

.hasNews #mainVis .l-main__slide {
  height: calc(100vh - 56vw);
}

.loaded #mainVis .l-main__slide {
  opacity: 1;
}

@media screen and (min-width: 769px) {
  #mainVis .l-main__slide {
    height: calc(100vh - 70px);
  }
  .hasNews #mainVis .l-main__slide {
    height: calc(100vh - 105px);
  }
}

#mainVis .l-main__scroll {
  display: none;
}

@media screen and (min-width: 769px) {
  #mainVis .l-main__scroll {
    display: block;
    bottom: 0;
    height: 11rem;
  }
}

#mainVis .scroll-label {
  font-size: 1.4rem;
  line-height: 1em;
  letter-spacing: .03em;
  top: 0%;
}

#mainVis .scroll-line.scroll-line {
  top: 35%;
}

#mainVis .visContent {
  position: absolute;
  width: auto;
  top: 0;
  left: 0;
  z-index: 10;
  color: #fff;
}

#mainVis .visContent > .inner {
  padding: 0 2.66667vw;
}

@media screen and (min-width: 769px) {
  #mainVis .visContent {
    top: calc(50% - 12.87879vw);
  }
  #mainVis .visContent > .inner {
    padding: 0;
    padding-left: 80px;
  }
}

#mainVis .visContent h2 {
  margin: 13.33333vw 0 4vw 0;
}

#mainVis .visContent h2 span {
  font-size: 80px;
  font-size: 10.66667vw;
  line-height: 1.25em;
  font-weight: 900;
  margin: 0;
}

@media screen and (min-width: 769px) {
  #mainVis .visContent h2 {
    margin: 0 0 20px 0;
  }
  #mainVis .visContent h2 span {
    font-size: 5.6rem;
    line-height: 1.16071em;
  }
}

@media screen and (min-width: 1320px) {
  #mainVis .visContent h2 span {
    font-size: 56px;
    font-size: 4.24242vw;
  }
}

#mainVis .visSummary {
  font-size: 28px;
  font-size: 3.73333vw;
  line-height: 1.42857em;
}

#mainVis .visSummary p {
  font-weight: 400;
}

@media screen and (min-width: 769px) {
  #mainVis .visSummary {
    font-size: 1.5rem;
    line-height: 1.8em;
  }
  #mainVis .visSummary p {
    font-weight: 400;
  }
}

@media screen and (min-width: 1320px) {
  #mainVis .visSummary {
    font-size: 15px;
    font-size: 1.13636vw;
  }
}

#mainVis .visBtn {
  margin-top: 4vw;
}

#mainVis .visBtn > * {
  width: 30.13333vw;
}

@media screen and (min-width: 769px) {
  #mainVis .visBtn {
    margin-top: 30px;
  }
  #mainVis .visBtn > * {
    width: 150px;
  }
}

@media screen and (min-width: 1320px) {
  #mainVis .visBtn > * {
    width: 11.36364vw;
  }
}

.l-main__slide .slider-navi {
  top: 78.66667vw;
  left: 2.66667vw;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .l-main__slide .slider-navi {
    top: 43.4%;
    left: auto;
    right: 2rem;
    display: block;
    position: absolute;
    width: auto;
    overflow: visible;
  }
}

.l-main__slide .slider-navi li {
  position: relative;
}

.l-main__slide .slider-navi li.is-current::after {
  opacity: 1 !important;
}

.l-main__slide .slider-navi li:after,
.l-main__slide .slider-navi li:nth-child(2):after,
.l-main__slide .slider-navi li:nth-child(3):after {
  top: .35rem;
  left: 2.2rem;
  font-size: 1rem;
  white-space: nowrap;
}

@media screen and (min-width: 769px) {
  .l-main__slide .slider-navi li:after,
  .l-main__slide .slider-navi li:nth-child(2):after,
  .l-main__slide .slider-navi li:nth-child(3):after {
    font-size: 1.2rem;
    left: -9rem;
    top: 1.3rem;
    font-weight: bold;
    text-align: right;
    opacity: .6;
  }
}

.visBottomCont {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 769px) {
  .visBottomCont {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.fixedBnrWrap .visBnr {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: -53.33333vw;
  left: 0;
  width: 100%;
  opacity: 1;
  z-index: 100;
  padding: 2.66667vw;
  transition: .7s;
}

.fixedBnrWrap .visBnr a {
  font-size: 0;
}

.up .fixedBnrWrap .visBnr {
  bottom: 0;
}

.fixedBnrWrap .visBnr img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
}

.fixedBnrWrap .visBnr a {
  display: block;
}

@media screen and (min-width: 769px) {
  .fixedBnrWrap .visBnr {
    background-color: transparent;
    left: auto;
    right: 26px;
    bottom: 26px;
    padding: 0;
    width: 256px;
    margin-left: auto;
    box-sizing: content-box;
    opacity: 0;
  }
  .home .fixedBnrWrap .visBnr, .home.up .fixedBnrWrap .visBnr {
    bottom: 91px;
  }
  .fixedBnrWrap .visBnr img {
    max-width: none;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
  .fixedBnrWrap .visBnr a {
    display: block;
  }
  .loaded .fixedBnrWrap .visBnr {
    opacity: 1;
  }
  .up .fixedBnrWrap .visBnr {
    bottom: 26px;
  }
  .hideBnr .fixedBnrWrap .visBnr {
    opacity: 0;
  }
  .bnrShadow .fixedBnrWrap .visBnr {
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
    -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 3px;
    bottom: 26px;
  }
}

#mainVis .visBnr {
  background-color: #f2f3f4;
  position: relative;
  padding: 5.06667vw 2.66667vw;
  opacity: 0;
  transition: .8s;
  transition-delay: .8s;
}

.loaded #mainVis .visBnr {
  opacity: 1;
}

#mainVis .visBnr img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
}

#mainVis .visBnr a {
  display: block;
}

@media screen and (min-width: 769px) {
  #mainVis .visBnr {
    display: none;
  }
}

#mainVis .visKeyword {
  height: 12.53333vw;
}

#mainVis .visKeyword > .inner {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 100%;
}

#mainVis .visKeyword > .inner > * {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

#mainVis .visKeyword .col-ttl {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  width: 26.66667vw;
}

#mainVis .visKeyword .bgElem {
  position: absolute;
  left: 2.66667vw;
  top: -2.66667vw;
}

#mainVis .visKeyword .bgElem img {
  width: 24.13333vw;
  opacity: .2;
}

#mainVis .visKeyword h2 {
  line-height: 1em;
  font-size: 24px;
  font-size: 3.2vw;
  margin: 0;
}

#mainVis .visKeyword .col-content {
  background-color: #fff;
  padding-left: 1.33333vw;
}

#mainVis .visKeyword .col-content > .inner {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  #mainVis .visKeyword {
    padding-left: 80px;
    height: 60px;
  }
  #mainVis .visKeyword .col-ttl {
    border-radius: 10px 0 0 0;
    width: 140px;
  }
  #mainVis .visKeyword .bgElem {
    left: 12px;
    top: -7px;
  }
  #mainVis .visKeyword .bgElem img {
    width: 116px;
  }
  #mainVis .visKeyword h2 {
    font-size: 1.5rem;
  }
  #mainVis .visKeyword .col-content {
    padding-left: 10px;
  }
}

.tagLoop {
  letter-spacing: -.4em;
  white-space: nowrap;
  font-size: 0;
}

.tagLoop > * {
  display: inline-block;
  letter-spacing: normal;
}

.tagLoop ul {
  letter-spacing: -.4em;
  white-space: nowrap;
  padding-inline-start: 0px;
}

.tagLoop ul > * {
  display: inline-block;
  letter-spacing: normal;
}

.tagLoop li {
  line-height: 1em;
}

.tagLoop li:nth-child(n+21) {
  display: none;
}

.tagLoop.move ul:first-child {
  animation: loop 40s -20s linear infinite;
}

.tagLoop.move ul:last-child {
  animation: loop2 40s linear infinite;
}

.tagLoop.move:hover ul:first-child, .tagLoop.move:hover ul:last-child {
  animation-play-state: paused;
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

.snsIcons {
  display: flex;
  align-items: center;
}

.snsIcons li {
  margin-right: 2.13333vw;
}

.snsIcons li:last-child {
  margin-right: 0;
}

.snsIcons a {
  width: 9.6vw;
  height: 9.6vw;
  display: block;
  font-size: 36px;
  font-size: 4.8vw;
  line-height: 9.6vw;
  color: #fff;
  background-color: #2a2a2a;
  text-align: center;
  border-radius: 100%;
}

.snsIcons a i {
  line-height: 9.6vw;
}

@media screen and (min-width: 769px) {
  .snsIcons li {
    margin-right: 8px;
  }
  .snsIcons li:last-child {
    margin-right: 0;
  }
  .snsIcons a {
    width: 40px;
    height: 40px;
    font-size: 2.0rem;
  }
  .snsIcons a i {
    line-height: 40px;
  }
  .snsIcons a:hover {
    opacity: 1;
  }
  .snsIcons li.facebook a:hover {
    background-color: #395ba8;
  }
  .snsIcons li.twitter a:hover {
    background-color: #1da1f2;
  }
  .snsIcons li.line a:hover {
    background-color: #06c755;
  }
}

.largeBnr {
  position: relative;
  background-color: #000;
  color: #fff;
}

.largeBnr > .inner {
  overflow: hidden;
  height: 72.93333vw;
}

.largeBnr .bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .6;
  transition: .6s;
  overflow: hidden;
}

.largeBnr .bgImg img {
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: .9s;
}

.largeBnr .effectImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/bg_02.webp");
}

.largeBnr .content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.largeBnr a {
  color: #fff;
}

.largeBnr .place {
  font-size: 20px;
  font-size: 2.66667vw;
  line-height: 1em;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", "Noto Sans JP", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 3.2vw;
  display: block;
  text-align: center;
}

.largeBnr .mainLink {
  display: block;
}

.largeBnr .mainLink h2 {
  font-size: 48px;
  font-size: 6.4vw;
  line-height: 1em;
  margin-bottom: 3.2vw;
  font-weight: 600;
  position: relative;
}

.largeBnr .mainLink h2::before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #fff;
}

.largeBnr .mainLink dl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.largeBnr .mainLink dt {
  font-size: 18px;
  font-size: 2.4vw;
  line-height: 1em;
  padding: 0.8vw 2.66667vw;
  border: 1px solid #fff;
  margin-right: 2.13333vw;
}

.largeBnr .mainLink dd {
  font-size: 30px;
  font-size: 4vw;
  line-height: 1em;
  letter-spacing: .04em;
}

.largeBnr .mainLink .circle {
  display: block;
  width: 10.8vw;
  height: 10.8vw;
  border-radius: 100%;
  border: 1px solid #fff;
  transition: .3s;
  text-align: center;
  margin: 4.8vw auto 0;
}

.largeBnr .mainLink .circle i {
  font-weight: 500;
  line-height: 10.8vw;
  font-size: 16px;
  font-size: 2.13333vw;
  transition: .3s;
  position: relative;
  left: 0;
}

@media screen and (min-width: 769px) {
  .largeBnr > .inner {
    max-height: 580px;
    height: 43.93939vw;
  }
  .largeBnr.ov .bgImg {
    opacity: .7;
  }
  .largeBnr.ov .bgImg img {
    transform: scale(1.02);
  }
  .largeBnr .effectImg {
    display: none;
  }
  .largeBnr .place {
    font-size: 2.0rem;
    margin-bottom: 28px;
  }
  .largeBnr .mainLink h2 {
    font-size: 4.8rem;
    margin-bottom: 18px;
  }
  .largeBnr .mainLink dt {
    font-size: 1.8rem;
    padding: 8px 40px;
    margin-right: 16px;
  }
  .largeBnr .mainLink dd {
    font-size: 3.0rem;
  }
  .largeBnr .mainLink .circle {
    width: 67px;
    height: 67px;
    margin: 36px auto 0;
  }
  .largeBnr .mainLink .circle i {
    line-height: 67px;
    font-size: 1.4rem;
  }
  .largeBnr .mainLink:hover {
    opacity: .9;
  }
  .largeBnr .mainLink:hover h2::before {
    width: 100%;
  }
  .largeBnr .mainLink:hover .circle {
    background-color: #aacc03;
    border: 1px solid #aacc03;
  }
  .largeBnr .mainLink:hover .circle i {
    left: 5px;
    color: #000;
  }
}

.faqWrap > * {
  margin-bottom: 4vw;
}

.faqWrap > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  .faqWrap > * {
    margin-bottom: 40px;
  }
  .faqWrap > *:last-child {
    margin-bottom: 0;
  }
}

.faqItem {
  background-color: #fff;
  border-radius: 5px;
  padding: 4vw;
}

@media screen and (min-width: 769px) {
  .faqItem {
    padding: 20px;
  }
}

.faqTtl {
  font-size: 38px;
  font-size: 5.06667vw;
  line-height: 1.6em;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 2.66667vw;
}

.faqTtl::before {
  content: 'Q.';
  font-size: 38px;
  font-size: 5.06667vw;
  position: relative;
  top: -1px;
  padding-right: 2.66667vw;
  color: #82c1ea;
}

@media screen and (min-width: 769px) {
  .faqTtl {
    font-size: 2.4rem;
    line-height: 1.6em;
    padding-bottom: 10px;
  }
  .faqTtl::before {
    font-size: 2.6rem;
    padding-right: 10px;
  }
}

.faqCont {
  padding: 4.8vw 0px 0px 8vw;
}

.faqCont > .inner {
  position: relative;
}

.faqCont > .inner::before {
  content: 'A.';
  position: absolute;
  top: 0;
  left: -6.66667vw;
  font-size: 40px;
  font-size: 5.33333vw;
  line-height: 1em;
  color: #aacc03;
}

.faqCont .summary > * {
  margin-bottom: 1.6em;
}

.faqCont .summary > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  .faqCont {
    padding: 18px 0px 0px 40px;
  }
  .faqCont > .inner::before {
    left: -30px;
    font-size: 2.2rem;
  }
}

.ttlSet_01 {
  display: flex;
  align-items: center;
}

.ttlSet_01 > *:first-child {
  margin-right: 1.33333vw;
}

@media screen and (min-width: 769px) {
  .ttlSet_01 > *:first-child {
    margin-right: 12px;
  }
}

.ttlStyle_01 {
  font-weight: 600;
  font-size: 28px;
  font-size: 3.73333vw;
  line-height: 1em;
  letter-spacing: .03em;
}

@media screen and (min-width: 769px) {
  .ttlStyle_01 {
    font-size: 1.4rem;
  }
}

.ttlStyle_02, .ttlStyle_03, .ttlStyle_04 {
  font-size: 36px;
  font-size: 4.8vw;
  line-height: 1.5em;
  padding-left: 2.93333vw;
  letter-spacing: .06em;
  border-left: 4px solid #aacc03;
  font-weight: 600;
  margin-bottom: 3.2vw;
}

@media screen and (min-width: 769px) {
  .ttlStyle_02, .ttlStyle_03, .ttlStyle_04 {
    font-size: 1.6rem;
    padding-left: 12px;
    margin-bottom: 6px;
  }
}

.ttlStyle_03 {
  border-left: 4px solid #82c1ea;
}

.ttlStyle_04 {
  border-left: 4px solid #e86e68;
}

.ttlStyle_05 {
  font-size: 32px;
  font-size: 4.26667vw;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: .03em;
  margin-bottom: 3.2vw;
}

@media screen and (min-width: 769px) {
  .ttlStyle_05 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
}

.ttlStyle_06, .ttlStyle_09, .ttlStyle_11 {
  font-size: 62px;
  font-size: 8.26667vw;
  line-height: 1em;
  margin: 0;
  font-weight: 700;
}

.ttlStyle_06 span.result, .ttlStyle_09 span.result, .ttlStyle_11 span.result {
  font-size: 30px;
  font-size: 4vw;
}

.ttlStyle_06 span.result > span::after, .ttlStyle_09 span.result > span::after, .ttlStyle_11 span.result > span::after {
  content: ',';
}

.ttlStyle_06 span.result > span:last-child::after, .ttlStyle_09 span.result > span:last-child::after, .ttlStyle_11 span.result > span:last-child::after {
  display: none;
}

@media screen and (min-width: 769px) {
  .ttlStyle_06, .ttlStyle_09, .ttlStyle_11 {
    font-size: 4.0rem;
  }
  .ttlStyle_06 span.result, .ttlStyle_09 span.result, .ttlStyle_11 span.result {
    font-size: 1.4rem;
  }
}

.ttlStyle_07, .ttlStyle_10 {
  color: #7b7b7c;
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1.6em;
}

@media screen and (min-width: 769px) {
  .ttlStyle_07, .ttlStyle_10 {
    font-size: 1.4rem;
  }
}

.ttlStyle_08 {
  font-size: 30px;
  font-size: 4vw;
  line-height: 1em;
  font-weight: 600;
}

@media screen and (min-width: 769px) {
  .ttlStyle_08 {
    font-size: 1.8rem;
  }
}

.ttlStyle_09 {
  text-align: center;
}

.ttlStyle_10 {
  text-align: center;
  color: #cbcbcb;
}

.ttlStyle_11 {
  font-size: 36px;
  font-size: 4.8vw;
}

@media screen and (min-width: 769px) {
  .ttlStyle_11 {
    font-size: 1.6rem;
  }
}

.ttlStyle_12 {
  font-size: 36px;
  font-size: 4.8vw;
  line-height: 1em;
  font-weight: 600;
  border-left: 4px solid #a5a5a5;
  padding-left: 2.66667vw;
  margin-bottom: 4vw;
  border-bottom: 1px solid #a5a5a5;
  padding-bottom: 1.33333vw;
}

@media screen and (min-width: 769px) {
  .ttlStyle_12 {
    font-size: 2.2rem;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}

.btnStyle_01 {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 9.46667vw;
  color: #fff;
  border-radius: 100px;
  border: 1px solid #fff;
  position: relative;
  overflow: hidden;
  line-height: 1em;
  padding: 2.66667vw;
  font-size: 24px;
  font-size: 3.2vw;
}

.btnStyle_01::before {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background-color: #82c1ea;
  transition: .3s;
}

.btnStyle_01 > span {
  position: relative;
}

@media screen and (min-width: 769px) {
  .btnStyle_01 {
    min-height: 50px;
    border-radius: 100px;
    padding: 16px;
    font-size: 1.4rem;
  }
  .btnStyle_01:hover {
    opacity: 1;
    border: 1px solid #82c1ea;
  }
  .btnStyle_01:hover::before {
    left: 0;
  }
}

@media screen and (min-width: 1320px) {
  .btnStyle_01 {
    padding: 1.21212vw;
    font-size: 14px;
    font-size: 1.06061vw;
  }
}

.btnStyle_02 {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: none;
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  background-color: #000;
  min-height: 14.4vw;
  padding: 4vw;
  font-weight: 500;
  font-size: 32px;
  font-size: 4.26667vw;
  line-height: 1em;
  letter-spacing: .03em;
  position: relative;
  overflow: hidden;
  color: #fff;
}

.btnStyle_02 > span {
  position: relative;
}

.btnStyle_02::before {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  transition: .3s;
  background-color: #aacc03;
}

@media screen and (min-width: 769px) {
  .btnStyle_02 {
    min-height: 60px;
    padding: 16px;
    font-size: 1.8rem;
  }
  .btnStyle_02:hover {
    opacity: .9;
  }
  .btnStyle_02:hover::before {
    left: 0;
  }
  .btnStyle_02:active::before {
    left: 0;
  }
}

.summaryStyle_01 {
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1.41667em;
}

@media screen and (min-width: 769px) {
  .summaryStyle_01 {
    font-size: 1.1rem;
    line-height: 1.81818em;
  }
}

.linkStyle_01 {
  line-height: 1em;
  font-size: 22px;
  font-size: 2.93333vw;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.linkStyle_01:before {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #2a2a2a;
  bottom: 0px;
  position: absolute;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

@media screen and (min-width: 769px) {
  .linkStyle_01:hover:before {
    animation: linemove_01 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
  }
}

@media screen and (min-width: 769px) {
  .linkStyle_01 {
    line-height: 1em;
    font-size: 1.2rem;
  }
}

.linkStyle_02, .linkStyle_03 {
  position: relative;
}

.linkStyle_02:before, .linkStyle_03:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #2a2a2a;
}

@media screen and (min-width: 769px) {
  .linkStyle_02:hover:before, .linkStyle_03:hover:before {
    width: 100%;
  }
}

.linkStyle_03 {
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1em;
  font-weight: 600;
}

@media screen and (min-width: 769px) {
  .linkStyle_03 {
    font-size: 1.1rem;
  }
}

.linkStyle_04 .labelTx {
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1em;
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.linkStyle_04 .labelTx::before {
  content: '';
  height: 1px;
  width: 100%;
  background-color: #2a2a2a;
  bottom: 0;
  position: absolute;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}

.linkStyle_04 .labelIcon {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
  position: relative;
  padding-left: 1.6vw;
  top: 4px;
  transition: .2s;
}

@media screen and (min-width: 769px) {
  .linkStyle_04 .labelTx {
    font-size: 1.2rem;
  }
  .linkStyle_04 .labelIcon {
    font-size: 1.2rem;
    padding-left: 4px;
  }
  .linkStyle_04:hover .labelTx::before {
    animation: linemove_01 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
  }
  .linkStyle_04:hover .labelIcon {
    top: 2px;
  }
}

.linkStyle_05 {
  text-decoration: underline;
  color: #2a9be5;
}

@media screen and (min-width: 769px) {
  .linkStyle_05:hover {
    text-decoration: none;
  }
}

.listStyle_01 {
  letter-spacing: -.4em;
}

.listStyle_01 > * {
  display: inline-block;
  letter-spacing: normal;
}

.listStyle_01 li {
  margin-right: 5.6vw;
}

.listStyle_01 li:last-child {
  margin-right: 0;
}

@media screen and (min-width: 769px) {
  .listStyle_01 li {
    margin-right: 14px;
  }
  .listStyle_01 li:last-child {
    margin-right: 0;
  }
}

.dlStyle_01 {
  display: flex;
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1.41667em;
}

.dlStyle_01 dt::after {
  content: ':';
  padding: 0 1.33333vw;
}

@media screen and (min-width: 769px) {
  .dlStyle_01 {
    font-size: 1.2rem;
    line-height: 1.66667em;
  }
  .dlStyle_01 dt::after {
    padding: 0 4px;
  }
}

.dlStyle_02 dt {
  font-size: 32px;
  font-size: 4.26667vw;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 3.2vw;
}

@media screen and (min-width: 769px) {
  .dlStyle_02 {
    display: flex;
    align-items: center;
  }
  .dlStyle_02 dt {
    font-size: 1.3rem;
    padding-right: 16px;
    margin-bottom: 0;
  }
}

.navStyle_01, .navStyle_02, .navStyle_03 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #cfcfcf;
  border-bottom: none;
  border-right: none;
}

.navStyle_01 li, .navStyle_02 li, .navStyle_03 li {
  width: 33.33333%;
  border: 1px solid #cfcfcf;
  border-left: none;
  border-top: none;
}

.navStyle_01 a, .navStyle_02 a, .navStyle_03 a {
  font-weight: 600;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 11.33333vw;
  overflow: hidden;
  position: relative;
  font-size: 26px;
  font-size: 3.46667vw;
  line-height: 1em;
}

.navStyle_01 a .labelTx, .navStyle_02 a .labelTx, .navStyle_03 a .labelTx {
  position: relative;
  transition: .3s;
}

.navStyle_01 a .labelTxEn, .navStyle_02 a .labelTxEn, .navStyle_03 a .labelTxEn {
  display: none;
}

.navStyle_01 a::before, .navStyle_02 a::before, .navStyle_03 a::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  width: 100%;
  transition: .3s;
  background-color: #aacc03;
}

@media screen and (min-width: 769px) {
  .navStyle_01 a, .navStyle_02 a, .navStyle_03 a {
    min-height: 51px;
    font-size: 1.5rem;
  }
  .navStyle_01 a:hover, .navStyle_02 a:hover, .navStyle_03 a:hover {
    opacity: 1;
  }
  .navStyle_01 a:hover .labelTx, .navStyle_02 a:hover .labelTx, .navStyle_03 a:hover .labelTx {
    color: #fff;
  }
  .navStyle_01 a:hover::before, .navStyle_02 a:hover::before, .navStyle_03 a:hover::before {
    left: 0;
  }
}

.navStyle_02 a, .navStyle_03 a {
  font-size: 22px;
  font-size: 2.93333vw;
}

.navStyle_02 a .labelIcon, .navStyle_03 a .labelIcon {
  font-size: 28px;
  font-size: 3.73333vw;
  line-height: 1em;
  padding-right: 1.06667vw;
  position: relative;
  transition: .3s;
}

.navStyle_02 a .labelIcon .myfont-fav, .navStyle_03 a .labelIcon .myfont-fav {
  position: relative;
  top: -1px;
}

.navStyle_02 a .labelIcon .myfont-user_01, .navStyle_03 a .labelIcon .myfont-user_01, .navStyle_02 a .labelIcon .myfont-user_02, .navStyle_03 a .labelIcon .myfont-user_02 {
  position: relative;
  top: -2px;
}

.navStyle_02 a:before, .navStyle_03 a:before {
  background-color: #82c1ea;
}

@media screen and (min-width: 769px) {
  .navStyle_02 a, .navStyle_03 a {
    font-size: 1.4rem;
  }
  .navStyle_02 a .labelIcon, .navStyle_03 a .labelIcon {
    font-size: 1.6rem;
    padding-right: 4px;
  }
  .navStyle_02 a:hover .labelIcon, .navStyle_03 a:hover .labelIcon {
    color: #fff;
  }
}

.navStyle_03 a:before {
  background-color: #e86e68;
}

.navStyle_03 li {
  width: 50%;
}

.navStyle_04 ul {
  letter-spacing: -.4em;
}

.navStyle_04 ul > * {
  display: inline-block;
  letter-spacing: normal;
}

.navStyle_04 li {
  min-width: 36vw;
  padding-right: 2.66667vw;
}

.navStyle_04 li:nth-child(2n) {
  padding-right: 0;
}

.navStyle_04 li:nth-child(n+3) {
  margin-top: 6.66667vw;
}

.navStyle_04 a {
  font-size: 36px;
  font-size: 4.8vw;
  font-weight: 600;
  line-height: 1em;
  position: relative;
  letter-spacing: .03em;
}

.navStyle_04 a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #fff;
}

@media screen and (min-width: 769px) {
  .navStyle_04 a:hover:before {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .navStyle_04 li {
    min-width: 0;
    margin-right: 24px;
    padding-right: 0;
  }
  .navStyle_04 li:last-child {
    margin-right: 0;
  }
  .navStyle_04 li:nth-child(n+3) {
    margin-top: 0;
  }
  .navStyle_04 a {
    font-size: 1.7rem;
  }
}

.navStyle_05 ul {
  display: inline;
  letter-spacing: -.4em;
}

.navStyle_05 ul > * {
  display: inline-block;
  letter-spacing: normal;
}

.navStyle_05 li {
  min-width: 32vw;
  padding-right: 2.66667vw;
  font-size: 24px;
  font-size: 3.2vw;
  line-height: 1em;
  margin-bottom: 4.26667vw;
}

.navStyle_05 li:nth-child(2n) {
  padding-right: 0;
}

.navStyle_05 a {
  position: relative;
}

.navStyle_05 a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #fff;
}

@media screen and (min-width: 769px) {
  .navStyle_05 a:hover:before {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .navStyle_05 ul {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .navStyle_05 ul:last-child {
    margin-bottom: 0;
  }
  .navStyle_05 li {
    display: block;
    min-width: 0;
    padding-right: 0;
    margin-right: 30px;
    font-size: 1.3rem;
    margin-bottom: 0;
  }
  .navStyle_05 li:last-child {
    margin-right: 0;
  }
}

.navStyle_06 ul {
  display: flex;
  align-items: center;
}

.navStyle_06 li {
  margin-right: 4vw;
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
}

.navStyle_06 li:last-child {
  margin-right: 0;
}

.navStyle_06 a {
  color: #b9b8b8;
  position: relative;
}

.navStyle_06 a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #b9b8b8;
}

@media screen and (min-width: 769px) {
  .navStyle_06 a:hover:before {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .navStyle_06 li {
    margin-right: 30px;
    font-size: 1.2rem;
  }
  .navStyle_06 li:last-child {
    margin-right: 0;
  }
}

.navStyle_07 ul {
  display: inline;
  letter-spacing: -.4em;
}

.navStyle_07 ul > * {
  display: inline-block;
  letter-spacing: normal;
}

.navStyle_07 li {
  margin-right: 4vw;
  font-size: 20px;
  font-size: 2.66667vw;
  line-height: 1em;
}

.navStyle_07 a {
  color: #b9b8b8;
  position: relative;
}

.navStyle_07 a:before {
  content: '';
  height: 1px;
  width: 0%;
  left: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: #b9b8b8;
}

@media screen and (min-width: 769px) {
  .navStyle_07 a:hover:before {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .navStyle_07 ul {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .navStyle_07 ul:last-child {
    margin-bottom: 0;
  }
  .navStyle_07 li {
    display: block;
    margin-right: 24px;
    font-size: 1.0rem;
  }
  .navStyle_07 li:last-child {
    margin-right: 0;
  }
}

.navStyle_08 ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.navStyle_08 li {
  width: 50%;
  padding: 0 1.33333vw;
  text-align: center;
  height: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navStyle_08 li::before, .navStyle_08 li::after {
  content: '';
  background-color: #434343;
  position: absolute;
}

.navStyle_08 li::before {
  width: 1px;
  height: 37.33333vw;
  right: 0;
  top: 6.33333vw;
}

.navStyle_08 li::after {
  width: 45.06667vw;
  height: 1px;
  left: 0;
  top: 0;
  display: none;
}

.navStyle_08 li:nth-child(2n)::before {
  display: none;
}

.navStyle_08 li:nth-child(2n)::after {
  left: auto;
  right: 0;
}

.navStyle_08 li:nth-child(n+3)::after {
  display: block;
}

.navStyle_08 a {
  display: block;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.navStyle_08 a > span {
  display: block;
  text-align: center;
}

.navStyle_08 a .labelIcon {
  width: 24.13333vw;
  height: 24.13333vw;
  text-align: center;
  background-color: #fff;
  border-radius: 100%;
  color: #000;
  font-size: 76px;
  font-size: 10.13333vw;
  margin: 0 auto 3.46667vw;
}

.navStyle_08 a .labelIcon i {
  line-height: 24.13333vw;
}

.navStyle_08 a .labelTx {
  font-weight: 600;
  font-size: 28px;
  font-size: 3.73333vw;
  line-height: 1em;
  margin-bottom: 1.33333vw;
}

.navStyle_08 a .labelTxEn {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
  color: #999999;
}

.navStyle_08 a::after {
  content: '';
  width: 0%;
  height: 1000px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #aacc03;
  transform: skewX(15deg);
  transition: width 0.8s cubic-bezier(0.06, 0.34, 0.63, 1);
  mix-blend-mode: multiply;
}

@media screen and (min-width: 769px) {
  .navStyle_08 li {
    border-right: 1px solid #434343;
    width: 25%;
    padding: 0 10px;
    height: auto;
    display: block;
  }
  .navStyle_08 li:last-child {
    border: none;
  }
  .navStyle_08 li::before, .navStyle_08 li::after {
    display: none;
  }
  .navStyle_08 li:nth-child(n+3)::after {
    display: none;
  }
  .navStyle_08 a {
    display: inline-block;
  }
  .navStyle_08 a .labelIcon {
    width: 80px;
    height: 80px;
    font-size: 3.6rem;
    margin: 0 auto 18px;
  }
  .navStyle_08 a .labelIcon i {
    line-height: 80px;
  }
  .navStyle_08 a .labelTx {
    font-size: 1.5rem;
    margin-bottom: 7px;
  }
  .navStyle_08 a .labelTxEn {
    font-size: 1.0rem;
  }
  .navStyle_08 a:hover {
    opacity: 1;
  }
  .navStyle_08 a:hover::after {
    width: 400%;
  }
}

.tagStyle_01 li {
  cursor: pointer;
  background-color: #f2f3f4;
  border: 1px solid #cfcfcf;
  border-radius: 100px;
  margin: 0 1.06667vw;
  position: relative;
  overflow: hidden;
  transition: .3s;
}

.tagStyle_01 li::before {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  transition: .3s;
  background-color: #aacc03;
}

.tagStyle_01 li > span {
  position: relative;
  display: flex;
  min-height: 5.33333vw;
  align-items: center;
  justify-content: center;
  padding: 1.33333vw 2.13333vw;
}

.tagStyle_01 li span.labelTx {
  line-height: 1em;
  font-size: 22px;
  font-size: 2.93333vw;
}

@media screen and (min-width: 769px) {
  .tagStyle_01 li {
    margin: 0 4px;
  }
  .tagStyle_01 li > span {
    min-height: 30px;
    padding: 10px 13px;
  }
  .tagStyle_01 li span.labelTx {
    font-size: 1.3rem;
  }
  .tagStyle_01 li a:hover {
    opacity: 1;
  }
  .tagStyle_01 li:hover {
    color: #fff;
    border: 1px solid #aacc03;
  }
  .tagStyle_01 li:hover a {
    color: #fff;
  }
  .tagStyle_01 li:hover::before {
    left: 0;
  }
}

.labelStyle_01 {
  background-color: #b30000;
}

.labelStyle_02 {
  background-color: #404241;
}

.txStyle_01 {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1em;
  font-weight: 600;
}

.txStyle_01 > span {
  font-size: 30px;
  font-size: 4vw;
  line-height: 1em;
  padding-left: 0.8vw;
}

@media screen and (min-width: 769px) {
  .txStyle_01 {
    font-size: 1.3rem;
  }
  .txStyle_01 > span {
    font-size: 1.6rem;
    padding-left: 3px;
  }
}

.categoryStyle_01, a .categoryStyle_01 {
  display: flex;
  align-items: center;
}

.categoryStyle_01 li span, a .categoryStyle_01 li span {
  color: #999999;
  font-size: 20px;
  font-size: 2.66667vw;
  line-height: 1em;
}

@media screen and (min-width: 769px) {
  .categoryStyle_01 li span, a .categoryStyle_01 li span {
    font-size: 1.0rem;
  }
}

.articleStyle_01 {
  position: relative;
  opacity: 0;
  transform: translateY(10px);
  transition: 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.articleStyle_01.is-active {
  opacity: 1;
  transform: translateY(0px);
}

.articleStyle_01 > a {
  display: block;
  background-color: #fff;
  padding-bottom: 13.33333vw;
  position: relative;
  height: 100%;
}

.articleStyle_01 > a h2 {
  font-size: 22px;
  font-size: 2.93333vw;
  line-height: 1.6em;
  font-weight: 600;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.articleStyle_01 > a:hover {
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 > a {
    padding-bottom: 50px;
  }
  .articleStyle_01 > a h2 {
    font-size: 1.3rem;
  }
  .articleStyle_01 > a:hover {
    opacity: .9;
  }
}

.articleStyle_01 > a .eyecatch {
  position: relative;
  overflow: hidden;
}

.articleStyle_01 > a .eyecatch .imgStyle {
  display: block;
  position: relative;
  overflow: hidden;
}

.articleStyle_01 > a .eyecatch .imgStyle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000;
}

.articleStyle_01 > a .eyecatch img {
  display: block;
  max-width: none;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  transition: .6s;
  transform-origin: 50% 50%;
}

.articleStyle_01.is-active > a .eyecatch .imgStyle::before {
  animation: 0.8s slideOutRight cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.articleStyle_01:nth-child(2n).is-active > a .eyecatch .imgStyle::before {
  animation: 0.8s slideOutDown cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.articleStyle_01:nth-child(3n).is-active > a .eyecatch .imgStyle::before {
  animation: 0.8s slideOutLeft cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.articleStyle_01:nth-child(4n).is-active > a .eyecatch .imgStyle::before {
  animation: 0.8s slideOutUp cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 > a:hover .eyecatch img {
    transform: scale(1.03);
  }
}

.articleStyle_01 > a .itemLabel {
  display: flex;
  position: absolute;
}

.articleStyle_01 > a .itemLabel li span {
  display: block;
  color: #fff;
  text-align: center;
  line-height: 1em;
  font-size: 20px;
  font-size: 2.66667vw;
  padding: 1.06667vw;
}

.articleStyle_01 > a .itemLabel.style_01 {
  left: 0;
  bottom: 0;
  display: none;
}

.articleStyle_01 > a .itemLabel.style_02 {
  display: none;
  right: 0;
  bottom: 0;
}

.articleStyle_01.new > a .itemLabel.style_01 {
  display: block;
}

.articleStyle_01.sale > a .itemLabel.style_02 {
  display: block;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 > a .itemLabel li span {
    font-size: 1.1rem;
    padding: 4px 8px;
  }
}

.articleStyle_01 > a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 6.13333vw;
  height: 6.13333vw;
  line-height: 6.13333vw;
  text-align: center;
  font-size: 22px;
  font-size: 2.93333vw;
  color: #fff;
  background-color: #aacc03;
  z-index: 10;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 > a::before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1.3rem;
  }
}

.articleStyle_01:nth-child(1) > a::before {
  background-color: #a48a13;
}

.articleStyle_01:nth-child(2) > a::before {
  background-color: #9a9a98;
}

.articleStyle_01:nth-child(3) > a::before {
  background-color: #9c541f;
}

.articleStyle_01 > a .content {
  padding: 2.66667vw 2.66667vw 0 2.66667vw;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 > a .content {
    padding: 10px 16px 0px 16px;
  }
}

.articleStyle_01 .directCheck {
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}

.articleStyle_01 .directCheck a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #404241;
  padding: 2.66667vw;
  height: 8vw;
  background-color: #000;
  color: #fff;
}

.articleStyle_01 .directCheck a > span {
  font-size: 26px;
  font-size: 3.46667vw;
  line-height: 1em;
}

.articleStyle_01 .directCheck a .labelIcon {
  padding-right: 1.06667vw;
  position: relative;
  top: -1px;
}

.articleStyle_01 .directCheck a.sold {
  display: none;
  background-color: #a5a5a5;
  border: 1px solid #a5a5a5;
  color: #fff;
  cursor: default;
  pointer-events: none;
}

.articleStyle_01.sold .directCheck a.normal {
  display: none;
}

.articleStyle_01.sold .directCheck a.sold {
  display: flex;
}

@media screen and (min-width: 769px) {
  .articleStyle_01 .directCheck a {
    padding: 6px 10px;
    height: 30px;
  }
  .articleStyle_01 .directCheck a > span {
    font-size: 1.3rem;
  }
  .articleStyle_01 .directCheck a .labelIcon {
    padding-right: 4px;
    top: 0;
  }
  .articleStyle_01 .directCheck a.normal .labelTx {
    display: none;
  }
  .articleStyle_01 .directCheck a:hover {
    opacity: 1;
    background-color: #82c1ea;
    border-right: 1px solid #82c1ea;
  }
}

@media screen and (min-width: 980px) {
  .articleStyle_01 .directCheck a.normal .labelTx {
    display: inline-block;
  }
}

.mrgStyle_01 {
  margin-bottom: 6.13333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_01 {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.mrgStyle_02 {
  margin-bottom: 2.93333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_02 {
    margin-bottom: 14px;
  }
}

.mrgStyle_03 {
  margin-bottom: 1.86667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_03 {
    margin-bottom: 5px;
  }
}

.mrgStyle_04 {
  margin-bottom: 3.2vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_04 {
    margin-bottom: 8px;
  }
}

.mrgStyle_05 {
  margin-bottom: 4.8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_05 {
    margin-bottom: 20px;
  }
}

.mrgStyle_06 {
  margin-bottom: 9.06667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_06 {
    margin-bottom: 28px;
  }
}

.mrgStyle_07 {
  margin-bottom: 6.66667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_07 {
    margin-bottom: 20px;
  }
}

.mrgStyle_08 {
  margin-bottom: 7.33333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_08 {
    margin-bottom: 28px;
  }
}

.mrgStyle_09 {
  margin-bottom: 6.13333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_09 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px) {
  .mrgStyle_10 {
    margin-top: 16px;
  }
}

.mrgStyle_11 {
  margin-bottom: 9.06667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_11 {
    margin-bottom: 0px;
  }
}

.mrgStyle_12 {
  margin-bottom: 9.06667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_12 {
    margin-bottom: 0px;
  }
}

.mrgStyle_13 {
  margin-bottom: 2.66667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_13 {
    margin-bottom: 30px;
  }
}

.mrgStyle_14 {
  padding-top: 12vw;
  padding-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_14 {
    padding-top: 82px;
    padding-bottom: 82px;
  }
}

.mrgStyle_15 {
  margin-bottom: 4vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_15 {
    margin-bottom: 20px;
  }
}

.mrgStyle_16 {
  margin-bottom: 1.33333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_16 {
    margin-bottom: 6px;
  }
}

.mrgStyle_17 {
  margin-bottom: 1.33333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_17 {
    margin-bottom: 6px;
  }
}

.mrgStyle_18 {
  margin-bottom: 1.6vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_18 {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 769px) {
  .mrgStyle_19 {
    margin-top: 38px;
    margin-bottom: 32px;
  }
}

.mrgStyle_20 {
  margin-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_20 {
    margin-bottom: 30px;
  }
}

.mrgStyle_21 {
  margin-bottom: 2.93333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_21 {
    margin-bottom: 14px;
  }
}

.mrgStyle_22 {
  margin-bottom: 3.46667vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_22 {
    margin-bottom: 24px;
  }
}

.mrgStyle_23 {
  margin-bottom: 1.33333vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_23 {
    margin-bottom: 46px;
  }
}

.mrgStyle_24 {
  padding-top: 12vw;
  padding-bottom: 4vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_24 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.mrgStyle_25 {
  padding-top: 8vw;
  padding-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_25 {
    padding-top: 82px;
    padding-bottom: 82px;
  }
}

.mrgStyle_26 {
  margin-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_26 {
    margin-bottom: 20px;
  }
}

.mrgStyle_27 {
  padding-top: 16vw;
  padding-bottom: 16vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_27 {
    padding-top: 142px;
    padding-bottom: 82px;
  }
}

.mrgStyle_28 {
  padding-top: 16vw;
  padding-bottom: 16vw;
}

.mrgStyle_28:nth-child(n+2) {
  padding-top: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_28 {
    padding-top: 142px;
    padding-bottom: 82px;
  }
  .mrgStyle_28:nth-child(n+2) {
    padding-top: 82px;
  }
}

.mrgStyle_29 {
  margin-bottom: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_29 {
    margin-bottom: 60px;
  }
}

.mrgStyle_30 {
  margin-top: 8vw;
}

@media screen and (min-width: 769px) {
  .mrgStyle_30 {
    margin-top: 40px;
  }
}

@media screen and (min-width: 769px) {
  .widedev {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
  .pcInline {
    display: inline !important;
  }
  .spInline {
    display: none !important;
  }
  .pcInlineB {
    display: inline-block !important;
  }
  .spInlineB {
    display: none !important;
  }
  .pcTd {
    display: table-cell !important;
  }
  .spTd {
    display: none !important;
  }
  .pcTbl {
    display: table !important;
  }
  .spTbl {
    display: none !important;
  }
}

@media screen and (min-width: 1320px) {
  .widedev {
    display: block !important;
  }
}

@media screen and (max-width: 320px) {
  .facebookWidget.sp {
    width: 100%;
  }
}
