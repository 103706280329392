@charset "utf-8";
// Color
$black:#000;
$white:#fff;

/////////
$key-black:#2a2a2a;
$key-gray1:#e5e5e5;
$key-gray2:#404241;
$key-gray3:#efefef;
$key-gray4:#a5a5a5;
$key-gray5:#cfcfcf;
$key-gray6:#b9b8b8;
$key-gray7:#434343;
$key-gray8:#f2f3f4;
$key-gray9:#7b7b7c;
$key-gray10:#999999;
$key-gray11:#cbcbcb;

$key-red:#b30000;

///////////
$accent-color1:#aacc03;
$accent-color2:#82c1ea;
$accent-color3:#e86e68;

$rank-color1:#a48a13;
$rank-color2:#9a9a98;
$rank-color3:#9c541f;
$rank-color4:$accent-color1;

$link-color:#2a9be5;

///////////
$text-color:$key-black;

// $key-color:#816049;
// $key-black:#4C3B39;
// $accent-color1:#956F29;

//$err-color:#cb2c2c;

///////////////
///////////
//$align-setting:inherit;
// $align-setting:justify;
/////////
//$hover-color:#888888;
//$hover-color:lighten($key-color,10%);
//$hover-color:lighten($key-black,10%);
//$hover-color:#E4DCCC;
//$hover-color:lighten($link-color,5%);
// $hover-color:lighten($key-color,5%);
//$hover-color:#66574F;
//$hover-color:lighten($link-color,5%);
//$hover-color:#EEE;
//$hover-color:lighten($key-red,5%);
//$active-color:#8CC63E;
//$hover-color:$key-blue2;
//$hover-color2:#f86767;
// $bg-color:$white;
// $bg-color-sp:$white;

$facebook-color:#395ba8;
$twitter-color:#1da1f2;
$pocket-color:#d3505a;
$google-color:#f63e28;
$youtube-color:#ed1b1b;
$line-color:#06c755;
$instagram-color:#b71503;
$mail-color:#696867;
$hatena-color:#00a4de;
$pinterest-color:#e60023;
$wechat-color:#1aac19;
$spotify-color:#1bd860;
$tiktok-color:#010101;

$base-font-size:14px;
$base-line-height:1.8;
//$base-font-family: "Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
//$base-font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
//$font-go:"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", 'Noto Sans JP',Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$fontEn: 'Roboto', sans-serif;
// $base-font-family:'Noto Serif JP', "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", 'Noto Serif JP', serif;
$base-font-family:'DM Sans','Noto Sans JP', sans-serif;
$fontEn: 'DM Sans', sans-serif;
$fontIt:"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", 'Noto Sans JP',Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

// $base-font-family:Hiragino Sans,"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// $base-font-family:'Marcellus','Shippori Mincho', "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", 'Noto Serif JP', serif;
// $fontJa:'Noto Sans JP',"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// $fontEn: 'Poppins', sans-serif;
// $fontMix:'Josefin Sans','Noto Sans JP',"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック",Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// $fontEn: 'Josefin Sans', sans-serif;
// $fontMin:"游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", 'Noto Serif JP', serif;
//$base-font-family:"Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", 'Noto Sans JP',Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// $fontEn:"Helvetica Neue", "Helvetica", "Arial", "Meiryo", sans-serif;
// $fontEn:Helvetica , "Helvetica Neue" , Arial , Verdana , Roboto , "游ゴシック" , "Yu Gothic" , "游ゴシック体" , "YuGothic" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
// $fontMin:"游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", 'Noto Serif JP', serif;
//font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
//$base-font-family:'Noto Sans JP',"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
//$fontEn: 'Roboto', sans-serif;
//$base-font-family:"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
//$fontGo:YuGothic,'Yu Gothic',sans-serif,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
//$fontGo:"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
//$fontMin: 'Noto Serif JP',"ヒラギノ明朝 ProN W3", "Times New Roman", "游明朝", YuMincho,  "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
//$base-font-family:'Open Sans', sans-serif;

//$fontMin: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
//$base-font-family:$fontGo;
//$fontEn: 'Roboto', sans-serif;
//$fontEn2: 'Abel', sans-serif;
//Layout

$design-num:1320;
$design-width:$design-num+px;
$design-num-sp:750;
$design-width-sp:$design-num-sp+px;
$break-point-design:$design-num+1+px;
$design-min-num:1000;
$design-min:$design-min-num+px;

$footer-size:658px;
$footer-size-sp:1280/$design-num-sp*100vw;

$headLine:34px;
$headLine-sp:50/$design-num-sp*100vw;

$header-num:72;
$header-size:$header-num+px;
$header-size-sp:120/$design-num-sp*100vw;

$header-total-num:72+34;
$header-total:$header-total-num+px;
$header-total-sp:(120+50)/$design-num-sp*100vw;

//////
$base-pad-num:80;
$base-pad:$base-pad-num+px;
$content-num-base:1160;
$content-width-base:$content-num-base+px;
$content-num:$content-num-base+$base-pad-num*2;
$content-width:$content-num+px;

$sp-pad-num:20;
$sp-pad:$sp-pad-num/$design-num-sp*100vw;

$break-point-full:1100px;
$break-point-large:960px;
//$break-point-middle:$content-width;
//$break-point-middle:600px;
$break-point-middle:769px;
//$break-point-small:600px;
$break-point-xsmall:480px-1;

$wrapMrg:($design-num - $content-num-base)/2/$design-num*100vw;

$kvH:85vh;
//$header-size:90px;
//$header-size:109px;

////////////////////////////////////////////////
$wrap-num_01:1016;
$wrap-width_01:$wrap-num_01+px;

////////////////////////////////////////////////
$modalShadow:0px 4px 10px rgba(0, 0, 0, 0.3);
$shadow_01:1px 2px 4px rgba(110, 124, 137, 0.09), 0px 0px 2px rgba(110, 124, 137, 0.25);
////////////////////////////////////////////////
$ease_01:.3s cubic-bezier(.55, .085, .68, .53);
$ease_02:1s cubic-bezier(.55, .085, .68, .53);

// sine
$ease-in-sine:cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine:cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine:cubic-bezier(0.445, 0.05, 0.55, 0.95);

// quad
$ease-in-quad:cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad:cubic-bezier(0.455, 0.03, 0.515, 0.955);

// cubic
$ease-in-cubic:cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic:cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic:cubic-bezier(0.645, 0.045, 0.355, 1);

// quart
$ease-in-quart:cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart:cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart:cubic-bezier(0.77, 0, 0.175, 1);

// quint
$ease-in-quint:cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint:cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint:cubic-bezier(0.86, 0, 0.07, 1);

// expo
$ease-in-expo:cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo:cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo:cubic-bezier(1, 0, 0, 1);

// circ
$ease-in-circ:cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ:cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ:cubic-bezier(0.785, 0.135, 0.15, 0.86);

// back
$ease-in-back:cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back:cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back:cubic-bezier(0.68, -0.55, 0.265, 1.55);
