@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.singleImg{
  img{
    @include imgset;
    width: 100%;
    aspect-ratio:1/1;
    object-fit: cover;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.itemMainSec{
  >.col-l{
    margin-bottom: 60/$design-num-sp*100vw;
  }
  >.col-r{
  }
}
@media screen and (min-width: $break-point-middle) {
.itemMainSec{
  display: flex;
  justify-content: space-between;
  >.col-l{
    width: 540/$content-num-base*100%;
    margin-bottom: 0;
  }
  >.col-r{
    width: 500/$content-num-base*100%;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.item-detail .item-title {
  @include fz_vw(30);
  font-weight: 500;
  margin-bottom: 16/$design-num-sp*100vw;
  position: relative;
}
@media screen and (min-width: $break-point-middle) {
.itemMainSec .item-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 26px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.detailInfo{
  position: relative;
  .movie {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
// ////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////
// .detailInfo .item-description dt{
//   @include fz_vw(36);
// }
// @media screen and (min-width: $break-point-middle) {
// .detailInfo .item-description dt{
//   font-size: 3.4rem;
// }
// }
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
