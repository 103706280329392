@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#belmoContent .category-title{
  justify-content: flex-end;
}
///////////////////////////////////////////
#belmoContent .breadcrumb{
  padding:140/$design-num-sp*100vw 20/$design-num-sp*100vw 10/$design-num-sp*100vw;
  background-color: #888;
  color: $white;
  margin-bottom: 0;
  .hasNews &{
    padding:190/$design-num-sp*100vw 20/$design-num-sp*100vw 10/$design-num-sp*100vw;
  }
  a{
    color: $white;
  }
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .breadcrumb{
  padding: 84px 24px 10px;
  .hasNews &{
    padding: (84+34)+px 24px 10px;
  }
}
}
///////////////////////////////////////////
#belmoContent .pager-wrap{
  a{
    position: relative;
    overflow: hidden;
    @include over_01($text-color);
  }
  span.current{
    font-weight: bold;
    text-decoration: underline;
  }
}
///////////////////////////////////////////
#belmoContent .cart-button-wrap .cart-button a{
  border-radius: 3px;
  background-color: $accent-color1;
}
///////////////////////////////////////////
#belmoContent .guide-section{
  width: 100%;
  padding-top: 140/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .guide-section{
  width: 100%;
  padding-top: 110px;
}
}
///////////////////////////////////////////
#belmoContent .contract-section,#belmoContent .contract-section{
  width: 100%;
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .contract-section,#belmoContent .contract-section{
  width: 100%;
  margin-bottom: 60px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
#belmoContent .contract-section dt{
  font-size: 1.8rem;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#belmoContent .price{
  @include fz_vw(30);
  line-height: 1em;
  font-weight: 600;
  span.tax{
    @include fz_vw(20);
  }
  span.yenIcon{
    @include fz_vw(22);
    line-height: 1em;
    padding-right: 6/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .price{
  font-size: 1.6rem;
  span.tax{
    font-size: 1.1rem;
  }
  span.yenIcon{
    font-size: 1.3rem;
    padding-right: 3px;
  }
}
}
#belmoContent .sale-discount-rate{
  @include fz_vw(22);
  line-height: 1em;
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .sale-discount-rate{
  font-size: 1.3rem;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#belmoContent .original-price{
  text-decoration: none;
  position: relative;
  display: inline-block;
  &::after{
    content: '';
    width: 100%;
    height: 1px;
    background-color: $black;
    position: absolute;
    top: 50%;
    left: 0;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#belmoContent .comapany-box{
  width: 100%;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#belmoContent .guide-section{
  .preSec b{
    font-weight: bold;
    @include fz_vw(30);
    margin-bottom: 20/$design-num-sp*100vw;
    display: block;
  }
  .preSec h3{
    @include fz_vw(32);
    line-height: 1em;
    font-weight: 600;
    border-left: 4px solid $key-gray4;
    padding-left: 20/$design-num-sp*100vw;
    margin-top: 50/$design-num-sp*100vw;
    &:first-child{
      margin-top: 0;
    }
    // margin-bottom: 30/$design-num-sp*100vw;
  }
  .preSec{
    >*{
      margin-bottom: 30/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  // p{
  //   margin-bottom: .5em;
  //   &:last-child{
  //     margin-bottom: 0;
  //   }
  // }
}
@media screen and (min-width: $break-point-middle) {
#belmoContent .guide-section{
  .preSec b{
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  .preSec h3{
    font-size: 1.8rem;
    padding-left: 10px;
    margin-bottom: 20px;
    margin-top: 40px;
    &:first-child{
      margin-top: 0;
    }
  }
  .preSec{
    >*{
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.cart-count{
  position: absolute;
  right: 0/$design-num-sp*100vw;
  top: -6/$design-num-sp*100vw;
  background: $key-red;
  color: #fff;
  width: 30/$design-num-sp*100vw;
  height: 30/$design-num-sp*100vw;
  line-height: 30/$design-num-sp*100vw;
  border-radius: 50%;
  text-align: center;
  @include fz_vw(20);
  font-weight: normal;
  .navStyle_02 &{
    display: none;
  }
}
@media screen and (min-width: $break-point-middle) {
.cart-count{
  position: absolute;
  right: -8px;
  top: -8px;
  background: $key-red;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: normal;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.add-cart .btn{
  border-radius: 3px;
}
.add-cart .add-cart-btn{
  background-color: $accent-color1;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
